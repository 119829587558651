import {
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Theme,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { MdMoreHoriz } from "react-icons/md";
import { SearchI } from "../../../../interfaces/servico";
import { getService } from "../../../../services/servico";
import GuidanceModal from "../GuidanceModal";
import ModalAttendenceOnline from "../ModalAttendenceOnline";
import ModalShedulingPresential from "../ModalShedulingPresential";

interface Props {
  service: SearchI;
  setShowSearchResult: (set: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
    height: 24,
    width: 24,
  },
}));

export default function MoreLink({
  service,
  setShowSearchResult,
}: Props): JSX.Element {
  const preventDefault = (event: React.SyntheticEvent) =>
    event.preventDefault();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [serviceSelected, setServiceSelected] = useState<SearchI | undefined>();
  const [openGuidanceModal, setOpenGuidanceModal] = useState(false);
  const [isPresentialModal, setIsPresentialModal] = useState<boolean | null>(null);
  const [openSchedulerPresential, setOpenSchedulerPresential] = useState<boolean>(false);
  const [openSchedulerOnline, setOpenSchedulerOnline] = useState<boolean>(false);
  const [serviceDetail, setService] = useState<SearchI>();
  const [showModalAttendenceOnline, setShowModalAttendenceOnline] =
    useState<boolean>(false);
  const [showModalShedulingPresential, setShowModalShedulingPresential] =
    useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getServiceEspecific = async () => {
    try {
      const { data } = await getService(service.slug);
      setService(data.results[0]);
    } catch (err) {
      console.log(err);
    }
  };

  const descriptionProblem = serviceDetail && serviceDetail.configuracao_agendamento.texto;

  const handleConfirm = () => {
    if (isPresentialModal) {
      setOpenSchedulerPresential(true);
    } else {
      setOpenSchedulerOnline(true);
    }
    setOpenGuidanceModal(false);
  };

  const handleOpenModal = (isPresential: boolean) => {
    setIsPresentialModal(isPresential);
    if (!descriptionProblem) {
      if (isPresential) {
        setOpenSchedulerPresential(true);
      } else {
        setOpenSchedulerOnline(true);
      }
    } else {
      setOpenGuidanceModal(true);
    }
  };

  useEffect(() => {
    getServiceEspecific();
  }, [!serviceDetail]);

  return (
    <>
      <Box>
        <Box className={classes.boxActionsIcon}>
          <IconButton
            aria-label="comments"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MdMoreHoriz className={classes.icon} style={{ color: "#fff" }} />
          </IconButton>
        </Box>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem>
            <Link href={`/${service.categoria_slug}/${service.slug}`} target="_new">
              Visualizar
            </Link>
          </MenuItem>
          {service.agendavel && (
            <MenuItem
              onClick={() => {
                setServiceSelected(service);
                handleOpenModal(true);
              }}
            >
              <Link href="#" onClick={preventDefault}>
                Agendamento presencial
              </Link>
            </MenuItem>
          )}
          {service.online && (
            <MenuItem
              onClick={() => {
                setServiceSelected(service);
                handleOpenModal(false);
              }}
            >
              <Link href="#" onClick={preventDefault}>
                Agendamento online
              </Link>
            </MenuItem>
          )}
        </Menu>
      </Box>

      {serviceSelected && (
        <ModalAttendenceOnline
          handleClose={() => {
            setOpenSchedulerOnline(false);
          }}
          open={openSchedulerOnline}
          type="Video"
          serviceSelected={serviceSelected}
          setShowResult={setShowSearchResult}
        />
      )}
      {serviceSelected && (
        <ModalShedulingPresential
          handleClose={() => {
            setOpenSchedulerPresential(false);
          }}
          open={openSchedulerPresential}
          serviceSelected={serviceSelected}
          setShowResult={setShowSearchResult}
        />
      )}
      <GuidanceModal
        open={openGuidanceModal}
        handleClose={() => setOpenGuidanceModal(false)}
        handleConfirm={handleConfirm}
        description={descriptionProblem}
      />
    </>
  );
}
