import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { hidePartOfEmail } from '../../utils/str';
import { checkCodeSignIsValid, requestCodeSign, sendCodeSignToValidate } from '../../services/assinatura';
import { MdCheckCircle } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { onTermFlowAccept } from '../../services/form-flow';
import Terms from '../Terms';
import getCookie from '../../utils/getCookies';

type TermsProps = {
  titulo: string;
  conteudo: string;
  versao: string;
  id: string;
}

interface Props {
  page: number;
  handleStep: (pageOrStep: number) => void;
  handleSubmit: (values: any, errors?: any) => void;
  hasTerms?: TermsProps | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
    },
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#264259',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 16,
    padding: 20,
  },
  subtitle: {
    opacity: 0.6,
    textAlign: 'center',
    fontWeight: 'normal',
    fontSize: 16,
  },

  input: {
    color: theme.palette.primary.main,
  },
  buttonForm: {
    backgroundColor: '#264259',
  },
  textField: {
  },
}));

export default function SignForm({
  page,
  handleStep,
  handleSubmit,
  hasTerms
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [valueCode, setValueCode] = useState<string>('');
  const [userData, setUserData] = useState<any>({});
  const [dataForm, setDataForm] = useState<any>({});
  const [, setHashSign] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [stateCode, setStateCode] = useState<string>('');
  const [endSign, setEndSign] = useState<boolean>(false);
  const [loadingTerms, setLoadingTerms] = useState<boolean>(false);
  const [stepLocal, setStepLocal] = useState<number>(0);

  useEffect(() => {
    setUserData(JSON.parse(getCookie('gov_user_data') || '{}'));
    setDataForm(JSON.parse(getCookie('gov_user_form_started') || '{}'));

  }, []);

  const requestCode = async () => {
    try {
      const { data } = await requestCodeSign(userData?.cpf, userData?.contato?.email);
      // b422c0706b3f44b48c8cc627f932a6c3
      setStateCode(data.state);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (userData?.cpf && userData?.contato?.email) {
      requestCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const sendCodetoValidate = async () => {
    try {

      if (valueCode && dataForm) {
        setLoading(true);
        const { data } = await sendCodeSignToValidate({
          code: valueCode,
          state: stateCode,
          identificador: dataForm.identificador,
          tipo: 'Solicitacao',
          internal_state: stateCode,
          client_id: atob(process.env.REACT_APP_FORMS_CLIENT_ID) ?? '',
        });
        if (data) {
          setHashSign(data.hash);

          await checkSign(data.hash);

        }
        setLoading(false);
        setEndSign(true);
      }
    } catch (error) {
      setLoading(false);
      alert(error?.response?.data?.message);
      console.log(error);
    }
  }

  const checkSign = async (hash) => {
    try {
      if (dataForm) {
        const { data } = await checkCodeSignIsValid(dataForm.identificador, hash);
        console.log(data);

      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitTerms = async () => {
    const dataForm = JSON.parse(getCookie('gov_user_form_started') || '{}')

    if (!hasTerms && !dataForm) return;
    try {
      setLoadingTerms(true);
      await onTermFlowAccept(dataForm.id, hasTerms.id);
      setLoadingTerms(false);
      setStepLocal(1);
    } catch (error) {
      console.log(error);
      setLoadingTerms(false);
    }
  }

  useEffect(() => {
    if (!hasTerms) {
      setStepLocal(1);
    }
  }, [hasTerms]);

  return (
    <Box>
      {stepLocal === 0 && hasTerms && (
        <Terms
          titulo={hasTerms.titulo}
          conteudo={hasTerms.conteudo}
          onSubmit={handleSubmitTerms}
          versao={hasTerms.versao}
          loading={loadingTerms}
        />
      )}

      {stepLocal === 1 && (
        <>
          {!endSign ? <>
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid>
                <Typography variant="h6" className={classes.title}>
                  Assinatura
                </Typography>
              </Grid>
              <Grid style={{ textAlign: 'center', marginBottom: 20 }}>
                <Typography className={classes.subtitle}>
                  Assine a solicitação com o código enviado para seu email: {hidePartOfEmail(userData?.contato?.email || '')}
                </Typography>
              </Grid>

              <TextField
                id='codeValidate'
                fullWidth
                label="Digite o código de 6 dígitos"
                variant="outlined"
                className={classes.textField}
                inputProps={{
                  className: classes.input,
                }}
                onChange={(e) => setValueCode(e.target.value)}
                disabled={loading}
              />
              {loading ? <CircularProgress /> : (<>
                <Button
                  className={classes.buttonForm}
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  style={{ marginTop: 20 }}
                  onClick={sendCodetoValidate}
                  disabled={!stateCode}
                >
                  Assinar
                </Button>
                <Button style={{ color: '#0F6FB7' }} variant='text' onClick={requestCode} >Reenviar e-mail</Button>
              </>)}

            </Grid>
          </>
            :
            <>
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
              >

                <Grid>
                  <MdCheckCircle size={30} color="#0BB07B" />
                </Grid>
                <Grid>
                  <Typography variant="h6" className={classes.title}>
                    Solicitação realizada com sucesso!
                  </Typography>
                </Grid>



                {loading ? <CircularProgress /> : (<>
                  <Button
                    className={classes.buttonForm}
                    variant="contained"
                    fullWidth
                    color="primary"
                    type="submit"
                    style={{ marginTop: 20 }}
                    onClick={() => history.push('/workspace/minhas_solicitacoes')}
                  >
                    Minhas solicitações
                  </Button>
                  <Button style={{ color: '#0F6FB7' }} variant='text' onClick={() => history.push('/')} >Home </Button>
                </>)}

              </Grid>
            </>}
        </>
      )}
    </Box>
  );
}
