import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxTitle: {
    display: 'flex',
    width: '100%',
    marginTop: 40,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    color: '#333333'
  },
  boxInfoService: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  infoService: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 680,
    height: 163,
    borderRadius: 4,
  },
  titleService: {
    margin: '26px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    textAlign: 'center',
  },
  detailsService: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 8,
  },
  ticket: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 30,
  },
  boxEvaluation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  evaluation: {
    margin: '16px 0px 200px 0px',
    width: 680,
    height: 618,
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary
  },
  infoSlider: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  evaluationService: {
    with: '100%',
    marginTop: 50,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 18,
    color: '#333333',
    textAlign: 'center',
  },
  boxRating: {
    margin: '16px 0px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonRating: {
    padding: 14,
    margin: '0px 8px',
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInfoRating: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    color: '#706E7A',
    marginTop: 20,
  },
  description: {
    width: '100%',
    marginTop: 20,
    maxWidth: 600,
    borderRadius: 4,
    minHeight: '142px auto',
    margin: '0px 10px',
    border: '1px solid #E1E6EF',
    fontFamily: 'Roboto',
  },
  buttonNext: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
    width: 94,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase'
  },
  buttonReturn: {
    fontFamily: 'Roboto',
    width: 94,
    fontSize: 14,
    fontWeight: 500,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase',
    backgroundColor: '#fff',
    marginRight: 10,
  },
  boxActions: {
    padding: '0px 40px',
    margin: '50px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  input: {
    color: '#333',
  },
  boxSuccess: {
    width: '100%',
    maxWidth: 680,
    marginTop: 60,
    marginBottom: 40,
    margin: 'auto',
    borderRadius: 4,
    flexDirection: 'column',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  boxIcon: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 16,
  },
  subTitleInfo: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    margin: '0px 10px',
  },
  actions: {
    flexWrap: 'wrap',
    with: '100%',
    display: 'flex',
    padding: '0px 40px',
    marginBottom: 40,
  },
  buttonSchedule: {
    width: 185,
    fontSize: 14,
    textTransform: 'uppercase',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: 4,
    padding: 10,
    margin: '10px 0px',
  },
  buttonReturnAll: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    margin: '10px 0px',
  },
  iconStar: {
    height: 24,
    width: 24,
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    gap: '16px',
  },
  radioButton: {
    color: theme.palette.primary.main,
    '&.Mui-checked': {
      color: theme.palette.primary.dark,
    },
  },
}));


export default useStyles;