import { Favorito } from '../../../interfaces/servico';

interface InitState {
  favorites: null | Favorito
}

export const initialState: InitState = {
  favorites: null
}

export const actionType = {
  GET_FAVORITES:'GET_FAVORITES'
}

export const reducer = (state, action) => {
  switch(action.type){
    case actionType.GET_FAVORITES:
      return {...state, favorites: action.favorites}
  }
} 