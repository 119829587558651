import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { MdClose, MdVisibility } from "react-icons/md";
import { IMyAttendences } from "../../../../interfaces/atendimento";

interface Props {
  handleClose: () => void;
  open: boolean;
  schedule: IMyAttendences;
}

export default function HistoryDialog({ open, handleClose, schedule }: Props) {
  const useStyles = makeStyles((theme: Theme) => ({
    boxTitle: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      justifyContent: "space-between",
      padding: "16px 20px 16px 24px",
    },
    title: {
      display: "flex",
      alignItems: "center",
      gap: 12,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: 400,
      textTransform: "uppercase",
      fontFamily: "Roboto",
    },
    subTitle: {
      fontWeight: 300,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: "24px",
      textTransform: "capitalize",
      marginLeft: 34,
    },
    iconTitle: {
      width: 24,
      height: 24,
    },
    closeButton: {
      border: "none",
      borderRadius: "50%",
      background: "transparent",
      cursor: "pointer",
    },
    closeIcon: {
      width: 24,
      height: 24,
      opacity: "0.6",
      color: "#fff",
    },
    boxContent: {
      padding: "32px 32px 37px 32px",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      gap: 15,
    },
    listItem: {
      padding: '0 !important',
      alignItems: "flex-start",
      "&::before": {
        flexShrink: 0,
        content: '""',
        display: "block",
        width: 5,
        height: 5,
        borderRadius: "50%",
        marginTop: 6,
        marginRight: 10,
        backgroundColor: "#333",
      },
    },
    listItemText: {
      fontSize: theme.typography.pxToRem(14),
      color: "#333333",
      fontWeight: 500,
      lineHeight: "22.4px",
    },
  }));
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogActions className={classes.boxTitle}>
        <Grid item xs={8}>
          <Typography className={classes.title}>
            <MdVisibility className={classes.iconTitle} />
            Anotações do atendimento
          </Typography>
          <Typography className={classes.subTitle}>
            {schedule?.protocolo_atendimento &&
              <>
                Nº Protocolo: {schedule?.protocolo_atendimento}
              </>
            }
          </Typography>
        </Grid>
        <button className={classes.closeButton} onClick={handleClose}>
          <MdClose className={classes.closeIcon} />
        </button>
      </DialogActions>
      <DialogContent className={classes.boxContent}>
        <List className={`${classes.list} ${classes.listItemText}`}>
          {schedule?.observacao_topicos?.map(item => (
            <ListItem className={classes.listItem} key={item.id}>
              {item.conteudo}
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}