/* eslint-disable linebreak-style */
const getCookie = (cname: string): string => {
  const name = `${cname}=`;
  const ca = decodeURIComponent(document.cookie).split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      let cookieValue = c.substring(name.length, c.length);
      // Check if the value is not a JWT token
      if (!(/^[A-Za-z0-9\-_]+\.[A-Za-z0-9\-_]+\.[A-Za-z0-9\-_]*$/.exec(cookieValue))) {
        // Check if the value is a valid Base64-encoded string
        if (/^(?:[A-Za-z0-9+\/]{4})*([A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/.test(cookieValue)) {
          // Decode the value from Base64
          try {
            cookieValue = atob(cookieValue);
          } catch (e) {
            console.error('Error decoding Base64 value:', e);
          }
        }
      }
      return decodeURIComponent(cookieValue);
    }
  }
  return '';
};

export default getCookie;
