import { createMuiTheme } from '@material-ui/core/styles';
import getCookie from '../utils/getCookies';


const colorTheme = (): string => {
  if (getCookie('perfil')) {
    switch (getCookie("perfil")) {
      case "Cidadão":
        return "#004F9F";

      case "Servidor":
        return "#004F9F";

      case "Empresa":
        return "#004F9F";

      default:
        return "#004F9F";
    }
  }
  return "#004F9F";
}

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: colorTheme(),
      dark: colorTheme(),
    },
    secondary: {
      main: "#055052",
    },
    text: {
      primary: "#fff",
    },
  },
  overrides: {
    MuiStepIcon: {
      root: {
        "&$active": {
          color: "#94C120",
        },
        "&$completed": {
          color: "#94C120",
        },
      },
    },
    MuiStepLabel: {
      label: {
        "&$active": {
          color: "#94C120",
        },
        "&$completed": {
          color: "#94C120",
        },
      },
    },
    MuiButton: {
      outlined: {
        borderRadius: 0,
        padding: "8px 8px",
        textTransform: "none",
      },
    },
  },
  typography: {
    body1: {
      fontFamily: "Mulish",
      textTransform: "none",
      fontWeight: "bold",
    },
    h2: {
      fontFamily: "Mulish",
      fontWeight: "bold",
      textAlign: "center",
      textShadow: "0px 6px 14px rgba(24, 39, 75, 0.12)",
    },
    h3: {
      fontFamily: "Mulish",
    },
    h4: {
      fontFamily: "Mulish",
      fontWeight: "bold",
      color: "#373F41",
    },
    h5: {
      fontFamily: "Mulish",
    },
    h6: {
      fontFamily: "Mulish",
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    subtitle1: {
      color: "#d3d3d3",
    },
  },
});

export default theme;
