export const nameSplitTwo = (name: string): string => {
    if ((name || "").split(" ").length > 2) {
        return `${name.split(" ")[0]} ${name.split(" ")[1][0]}.`;
    }
    return name;
};

export const nameSplitOne = (name: string): string => {
    if ((name || "").split(" ").length > 2) {
        return `${name.split(" ")[0]}`;
    }
    return name;
};


export const firstName = (name: string): string => {
    if (!name) return "não informado";
    const trimmedName = name.trim();
    const normalizedName = trimmedName.replace(/\s+/g, ' ');
    return normalizedName.split(' ')[0];
};