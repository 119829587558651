import React from 'react';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { RouteLayout, ScrollToTop } from './Components';
import {
  Home,
  MyData,
  Terms,
  MySystems,
  MySpace,
  Permission,
  SchedulingRequest,
  AttendenceRequest,
  Activity,
  Favorite,
  Privacy,
  DigitalService,
  DetailDigitalService,
  Security,
  AttendanceOnlineChat,
  MyScheduling,
  TempForm,
  EvaluationFlow,
  EvaluationAttPresential,
} from './Pages';
import { ToastMessageProvider } from './contexts/toastMessage';
import WithHeader from './Components/Layouts/WithHeader';
import EvaluationAttInterno from './Pages/EvaluationAttInterno';
import EvaluationAttOnline from './Pages/EvaluationAttOnline';
import MyRequests from './Pages/MyRequests';
import EvaluationAttOrganic from './Pages/EvaluationAttOrganic';
import BlankLayout from './Components/Layouts/BlankLayout';
import DocRequestView from './Pages/DocRequestView';
import EvaluationService from './Pages/EvaluationService';
import ServiceSearchEvaluation from './Pages/ServiceSearchEvaluation';
import GetServiceEvaluation from './Pages/GetServiceEvaluation';
import ServiceSearchEvaluationByQrCode from './Pages/ServiceSearchEvaluationByQrCode';

function App(): JSX.Element {
  return (
    <ToastMessageProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Switch>
          <Redirect exact from="/" to="/workspace" />
          <RouteLayout
            path="/workspace"
            exact
            isAuth
            component={Home}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/termos"
            exact
            isAuth
            component={Terms}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/permissoes"
            exact
            isAuth
            component={Permission}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/agendamentos_solicitados"
            exact
            isAuth
            layout={WithHeader}
            component={SchedulingRequest}
          />
          <RouteLayout
            path="/workspace/atividades"
            exact
            isAuth
            component={Activity}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/minha-area"
            exact
            isAuth
            component={MySpace}
            layout={WithHeader}
          />

          <RouteLayout
            path="/workspace/servico-digital"
            exact
            isAuth
            component={DetailDigitalService}
            layout={WithHeader}
          />

          <RouteLayout
            path="/workspace/formularios/:slug"
            exact
            isAuth
            component={TempForm}
            layout={WithHeader}
          />

          <RouteLayout
            path="/workspace/servico-digital/:tema_slug/:tema"
            exact
            isAuth
            component={DigitalService}
            layout={WithHeader}
          />

          <RouteLayout
            path="/workspace/favoritos"
            exact
            isAuth
            component={Favorite}
            layout={WithHeader}
          />

          <RouteLayout
            path="/workspace/minha-area/meus-dados"
            exact
            isAuth
            component={MyData}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/minha-area/meus-sistemas"
            exact
            isAuth
            component={MySystems}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/meus_agendamentos"
            exact
            isAuth
            component={MyScheduling}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/minha-area/privacidade"
            exact
            isAuth
            component={Privacy}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/atendimento_online/:id"
            exact
            isAuth
            component={AttendanceOnlineChat}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/atendimentos_solicitados"
            exact
            isAuth
            component={AttendenceRequest}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/seguranca"
            exact
            isAuth
            component={Security}
            layout={WithHeader}
          />
          <RouteLayout
            path="/workspace/minhas_solicitacoes"
            exact
            isAuth
            component={MyRequests}
            layout={WithHeader}
          />

          <RouteLayout
            path="/workspace/minhas_solicitacoes/view_doc/:uuid_doc/:identificador_solicitacao"
            exact
            isAuth
            component={DocRequestView}
            layout={BlankLayout}
          />
          <RouteLayout
            path="/workspace/avaliar_atendimento_presencial/:ticket"
            exact
            layout={WithHeader}
            component={EvaluationAttPresential}
          />
          <RouteLayout
            path="/workspace/avaliar_atendimento_interno/:ticket"
            exact
            layout={WithHeader}
            component={EvaluationAttInterno}
          />
          <RouteLayout
            path="/workspace/avaliar_atendimento_online/:ticket"
            exact
            layout={WithHeader}
            component={EvaluationAttOnline}
          />
          <RouteLayout
            path="/workspace/avaliar_atendimento_diario/:ticket"
            exact
            layout={WithHeader}
            component={EvaluationAttOrganic}
          />
          <RouteLayout
            path="/workspace/avaliar_servico/:servico_slug/:perfil?"
            exact
            layout={WithHeader}
            component={EvaluationService}
          />
          <RouteLayout
            path="/workspace/avaliar_fluxo/:id"
            exact
            isAuth
            layout={WithHeader}
            component={EvaluationFlow}
          />
          <RouteLayout
            path="/workspace/buscar_servico/avaliar"
            exact
            layout={WithHeader}
            component={ServiceSearchEvaluation}
          />
          <RouteLayout
            path="/workspace/buscar_servico/avaliar/:servico_slug"
            exact
            layout={WithHeader}
            component={GetServiceEvaluation}
          />

          <RouteLayout
            path="/workspace/avaliacao/:slug/anonima"
            exact
            layout={WithHeader}
            component={ServiceSearchEvaluationByQrCode}
          />

          <RouteLayout
            path="/workspace/qrcode/avaliacao/:ticket_qr/anonima"
            exact
            layout={WithHeader}
            component={ServiceSearchEvaluationByQrCode}
          />
        </Switch>
      </BrowserRouter>
    </ToastMessageProvider>
  );
}

export default App;

