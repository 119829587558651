import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Icon,
  IconButton,
} from '@material-ui/core';
import { Breadcrumb } from '../../Components';
import { useHistory } from 'react-router-dom';
import { MdArrowBack } from "react-icons/md";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  title: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(28),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(24),
    }
  },
  boxIndicatorsBack: {
    width: "100%",
    margin: "20px 0px",
  },
  indicatorsBack: {
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    height: 225,
    [theme.breakpoints.down("xs")]: {
      height: 294,
    },
  },
  cardContent: {
    padding: 30,
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
      alignItems: "center",
      padding: "20px 10px 0px 10px",
    },
  },
  iconAccess: {
    color: theme.palette.primary.main,
    marginBottom: 20,
    marginTop: 8,
    fontSize: 50,
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      marginTop: 0,
      marginBottom: 0,
    },
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 16,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      textAlign: "center",
    },
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
    marginRight: 147.5,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 16,
      marginRight: 0,
      textAlign: "center",
    },
  },
  boxButton: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
  button: {
    height: 44,
  },
  boxIndicatorsBackHome: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBackHome: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
}));

export default function MySpace(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const linksBreadcrumb = [
    {
      name: 'Minha área',
      redirectTo: '#',
    },
  ];
  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <Typography
                  className={classes.title}
                >
                  Minha área
                </Typography>
                <Typography
                  style={{
                    fontFamily: "Roboto",
                    fontWeight: "normal",
                    fontSize: 18,
                    color: "#373F41",
                    letterSpacing: 0.3,
                  }}
                >
                  Gerencie suas informações, privacidade e segurança
                </Typography>
              </Box>
              <Box className={classes.boxIndicatorsBackHome}>
                <Box className={classes.indicatorsBackHome}>
                  <IconButton onClick={() => history.push("/workspace")}>
                    <MdArrowBack />
                  </IconButton>
                  <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                    Voltar
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    MEUS DADOS
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Veja os seus dados básicos.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    badge
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() =>
                    history.push("/workspace/minha-area/meus-dados")
                  }
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    Meus agendamentos
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Veja os seus agendamentos.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    event
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/workspace/meus_agendamentos")}
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    segurança
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Aumente a segurança da sua conta.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    https
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => history.push("/workspace/seguranca")}
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    privacidade
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Aumente a confiabilidade da sua conta e gerencie o acesso a
                    seus dados.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    admin_panel_settings
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() =>
                    history.push("/workspace/minha-area/privacidade")
                  }
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <Box>
                  <Typography className={classes.textCard}>
                    Minhas solicitações
                  </Typography>
                  <Typography className={classes.subTextCard}>
                    Listagem de solicitações.
                  </Typography>
                </Box>
                <Box>
                  <Icon
                    className={`${classes.iconAccess} material-icons-round`}
                  >
                    post_add
                  </Icon>
                </Box>
              </CardContent>
              <Box component="span" m={4} className={classes.boxButton}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() =>
                    history.push("/workspace/minhas_solicitacoes")
                  }
                >
                  VER MAIS
                </Button>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
