/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton, makeStyles, Theme, Box, Link, CircularProgress, Typography } from "@material-ui/core";
import { MdMoreVert, MdEvent, MdDelete, MdVisibility } from "react-icons/md";
import { SearchI, ServicosFavorito } from "../../../../../interfaces/servico";
import { getAtividades, getFavoriteService, getService, removeFavoriteService } from "../../../../../services/servico";
import ModalShedulingPresential from "../../../../../Components/SearchServices/components/ModalShedulingPresential";
import ModalAttendenceOnline from "../../../../../Components/SearchServices/components/ModalAttendenceOnline";
import getCookie from "../../../../../utils/getCookies";



const useStyles = makeStyles((theme: Theme) => ({
  itemMenuAgendar: {
    display: "flex",
    letterSpacing: 0.3,
    textDecoration: "none",
    padding: '2px 0px',
    "&:hover": {
      textDecoration: "none",
    },
  },
  itemMenuRemover: {
    color: "#264259",
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    fontWeight: "normal",
    paddingTop: 5,
    paddingBottom: 5,
  },
  boxActionsIcon: {
    width: 40,
    height: 40,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textLink: {
    fontSize: 16,
    fontWeight: 500,
    color: '#333333',
    fontFamily: "Roboto",
  },
  iconFavorite: {
    width: 24,
    height: 24,
  },
}));

interface Props {
  service_slug: string;
  favoriteId: number;
  agendavel: boolean;
  online: boolean;
  categoria_slug: string;
  setFavorites: (favoritos: ServicosFavorito) => void;
}

export default function Menus({ service_slug, favoriteId, agendavel, online, categoria_slug, setFavorites }: Props) {
  const classes = useStyles();
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceSelected, setServiceSelected] = useState<SearchI | undefined>();
  const [showModalAttendenceOnline, setShowModalAttendenceOnline] = useState<boolean>(false);
  const [showModalShedulingPresential, setShowModalShedulingPresential] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const token: string | null = getCookie('gov_access_token_sso');
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAllFavorites = async (page?: number) => {
    if (token) {
      try {
        const { data } = await getFavoriteService(token, page);

        if (data?.results) {
          setFavorites(data);
        }
      } catch (error) {
        console.log(error);
      }

    }

  };


  const handeRemove = async (favoriteId: number) => {
    if (token) {
      try {
        setLoading(true);
        await removeFavoriteService(favoriteId, token);
        await getAllFavorites(1);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleModalOnline = async (slug_text: string) => {
    if (tokenSiseci) {
      setLoading(true);
      try {
        const { data } = await getService(slug_text);
        setServiceSelected(data.results[0]);
      } catch (error) {
        console.log(error);
      }
      setAnchorEl(null);
      setLoading(false);
    }
  }

  function stringToSlug(str: string): string {
    return str
      ? str
        .toLowerCase()
        .replace(/\s+/g, '-')
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[^a-z0-9\-]/g, '')
      : '';
  }

  return (
    <Box>
      <Box className={classes.boxActionsIcon}>
        <IconButton
          aria-label="comments"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MdMoreVert className={classes.iconFavorite} style={{ color: '#fff' }} />
        </IconButton>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {loading ? (
          <MenuItem className={classes.itemMenuAgendar}>
            <CircularProgress
              color="primary"
              style={{ width: 25, height: 25 }}
            />
          </MenuItem>
        ) : (
          <React.Fragment>
            <MenuItem>
              <Link
                href={`/${stringToSlug(categoria_slug)}/${service_slug}`}
                target="_new"
                className={classes.itemMenuAgendar}
              >
                <MdVisibility className={classes.iconFavorite} style={{ marginRight: 10 }} />
                <Typography className={classes.textLink}>
                  Visualizar serviço
                </Typography>
              </Link>
            </MenuItem>
            {agendavel && (
              <MenuItem>
                <Link
                  href="#"
                  className={classes.itemMenuAgendar}
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    setShowModalShedulingPresential(true);
                    handleModalOnline(service_slug);
                  }}
                >
                  <MdEvent className={classes.iconFavorite} style={{ marginRight: 10 }} />
                  <Typography className={classes.textLink}>
                    Agendamento presencial
                  </Typography>
                </Link>
              </MenuItem>
            )}
            {online && (
              <MenuItem>
                <Link
                  href="#"
                  className={classes.itemMenuAgendar}
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    setShowModalAttendenceOnline(true);
                    handleModalOnline(service_slug);
                  }}
                >
                  <span style={{ marginRight: 10 }} className="material-icons">
                    support_agent
                  </span>
                  <Typography className={classes.textLink}>
                    Agendamento online
                  </Typography>
                </Link>
              </MenuItem>
            )}
            <MenuItem
              onClick={async () => {
                await handeRemove(favoriteId);
                handleClose();
              }}
              className={classes.itemMenuRemover}
            >
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <React.Fragment>
                  <MdDelete className={classes.iconFavorite} style={{ marginRight: 10, color: '#F03D3D' }} />
                  <Typography className={classes.textLink}>Remover favorito</Typography>
                </React.Fragment>
              )}
            </MenuItem>
          </React.Fragment>
        )}
      </Menu>

      {serviceSelected && (
        <ModalAttendenceOnline
          handleClose={() => {
            setShowModalAttendenceOnline(false);
          }}
          open={showModalAttendenceOnline}
          type="Video"
          serviceSelected={serviceSelected}
          setShowResult={() => false}
        />
      )}
      {serviceSelected && (
        <ModalShedulingPresential
          handleClose={() => {
            setShowModalShedulingPresential(false);
          }}
          open={showModalShedulingPresential}
          serviceSelected={serviceSelected}
          setShowResult={() => false}
        />
      )}
    </Box>
  );
}
