export function adicionarStateNaURL(url: string, groupId: string): string {
  console.log({url});
  
  const hasHttpPrefix = /^http(s)?:\/\//.test(url);
  if (!hasHttpPrefix) {
    url = `http://${url}`;
  }

  const hasStateParam = /\bstate=/.test(url);
  if (hasStateParam) {
    return url.replace(/(\bstate=)[^&]*/, `$1${groupId}`);
  }
  return `${url}`;
}