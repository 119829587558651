import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Card,
  CardContent,

} from "@material-ui/core";

import MenuFavorites from "./Menus";
import { ServicoFavorito, ServicosFavorito } from "../../../../interfaces/servico";

const useStyles = makeStyles((theme: Theme) => ({
  mainCard: {
    color: "#373F41",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: 'center',
    minHeight: 140,
    height: 'auto',
  },
  boxContent: {
    paddingTop: 10,
  },
  textTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 16,
    fontWeight: 500,
    marginBottom: 8,
    overflow: "auto",
    paddingLeft: 20,
  },
  textSubTitle: {
    color: "#373F41",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 400,
    opacity: 0.6,
    overflow: "auto",
    paddingLeft: 20,
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

interface Props {
  favorite: ServicoFavorito;
  setFavorites: (favoritos: ServicosFavorito) => void;
}

export default function CardFavorite({ favorite, setFavorites
}: Props): JSX.Element {
  const classes = useStyles();
  return (
    <Card className={classes.mainCard}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.boxContent}>
          <Typography className={classes.textTitle}>
            {favorite.servico.titulo}
          </Typography>
          <Typography className={classes.textSubTitle}>
            Órgão: {favorite.servico.orgao_responsavel}
          </Typography>
        </Box>
        <Box className={classes.icons}>
          <MenuFavorites
            setFavorites={setFavorites}
            agendavel={favorite.servico.agendavel}
            categoria_slug={favorite.servico.categoria_nome}
            online={favorite.servico.online}
            service_slug={favorite.servico.slug}
            favoriteId={favorite.id}
          />
          <Box />
        </Box>
      </CardContent>
    </Card>
  );
}
