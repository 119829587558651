import { AxiosResponse } from 'axios';
import { apisiseci, apisso } from './api';
import { UserData, UserDataMti } from '../interfaces/user';
import { setCookie } from '../utils/setCookie';
import getCookie from '../utils/getCookies';

export const saveTokens = (
  tokenSSO: string
): void => {
  setCookie('gov_access_token_sso', tokenSSO, 7);
};

export const clearStorage = (): void => {
  const cookiesToDelete = [
    'gov_access_token_sso',
    'pathname_redirect_gov',
    'gov_user_data'
  ];

  for (const cookie of cookiesToDelete) {
    document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};

export const getPathToRedirectLogin = (): string => {
  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${atob(process.env.REACT_APP_SSO)}/sso/cidadao?client_id=${atob(process.env.REACT_APP_CLIENT_ID)
      }`;
  }
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${atob(process.env.REACT_APP_SSO_SECUNDARY)}/auth?client_id=${atob(process.env.REACT_APP_CLIENT_ID)}&response_type=${atob(process.env.REACT_APP_RESPONSE_TYPE)}&redirect_uri=${atob(atob(process.env.REACT_APP_REDIRECT_URI))}`;
};

export const loginRedirectPrivider = (pathname: string): void => {
  clearStorage();
  setCookie('pathname_redirect_gov', pathname);
  (window as any).open(getPathToRedirectLogin(), '_self');
};

export const logout = async () => {
  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    clearStorage();
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (window as any).open(
      `${atob(process.env.REACT_APP_SSO)}/sso/sair?client_id=${atob(process.env.REACT_APP_CLIENT_ID)
      }&redirect_uri=${window.location.href}`,
      '_self'
    );
  } else {
    clearStorage();
    (window as any).open(
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${atob(process.env.REACT_APP_SSO_SECUNDARY)}/logout?client_id=${atob(process.env.REACT_APP_CLIENT_ID)
      }&response_type=${atob(process.env.REACT_APP_RESPONSE_TYPE)
      }&redirect_uri=${window.location.href}`,
      '_self'
    );
  }
};

export const getDataUserSso = (token: string): Promise<AxiosResponse> =>
  apisso.get('/cidadaos/pro/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getDataUserMti = (
  token: string,
  access_token_mti: string
): Promise<AxiosResponse> =>
  apisiseci.get('/profile/externo/', {
    headers: {
      Authorization: `Bearer ${token}`,
      'Authorization-Externo': access_token_mti,
    },
  });

export const validateUserSSO = (code: string): Promise<AxiosResponse> =>
  apisso.post(
    '/validar_cidadao/',
    {
      code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

export const loginSigp = (
  code: string,
  redirect_uri?: string
): Promise<AxiosResponse> => {
  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    return apisiseci.post('/login-sigp/', {
      code,
    });
  }
  return apisiseci.post(
    '/login/externo/',
    {
      code,
      redirect_uri:
        redirect_uri || atob(process.env.REACT_APP_REDIRECT_URI),
    },
    {
      headers: {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        Authorization: atob(process.env.REACT_APP_API_SISECI_KEY),
      },
    }
  );
};

const mtiDataUserAdapter = (data: UserDataMti): UserData => {
  const dataF = {
    nome: data.name || '',
    data_nascimento: data.dataNascimento || '',
    nome_mae: data.nomeMae || '',
    cpf: data.preferred_username || data.username || data.cpf || '',
    contato: {
      email: data.email || '',
    },
  };
  return dataF as UserData;
};

export const getUserData = async (token: string, access_token_mti: string) => {
  if (atob(process.env.REACT_APP_SSO_LOGIN_PROVIDER) === 'primary') {
    const { data } = await getDataUserSso(token);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return data[0];
  }

  const { data } = await getDataUserMti(access_token_mti, token);
  return mtiDataUserAdapter(data);
};

export const sendCodeForSISECI = async (code: string, history: any) => {
  const pathname = getCookie('pathname_redirect_gov');
  try {
    const { data } = await loginSigp(
      code,
      `${window.location.origin}${window.location.pathname}`
    );

    if (data.access_token) {
      saveTokens(data.access_token);
      const spathname = pathname === '/' ? '/acesso-rapido' : pathname;
      history.push(spathname || '/acesso-rapido', {
        message: data.message ? data.message : '',
      });
    }
  } catch (err) {
    clearStorage();
    console.log(err, " errlogin")
    history.push(pathname || '/', {
      message: 'Erro ao processar o login',
    });
  }
};

export const sendImageProfile = async (token: string, file: File) => {
  const formData = new FormData();
  formData.append('arquivo', file);

  return apisso.post('cidadaos/foto/', formData, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getImageProfile = async (token: string) => {
  return apisso.get('cidadaos/foto/', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
