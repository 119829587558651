import { AxiosResponse } from 'axios';
import { apisiseci } from './api';

export interface IParamsHistory {
  status: string;
}

export const historico = (token: string, page: number, params?: IParamsHistory): Promise<AxiosResponse> => apisiseci
  .get(`agendamento/historico_internos/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const getHistorico = (token: string, id: number): Promise<AxiosResponse> => apisiseci
  .get(`agendamento/historico_internos/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });