import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import { Breadcrumb } from '../../Components';
import { MdArrowForward, MdClose } from "react-icons/md";
import { useHistory } from 'react-router-dom';
import {  searchServiceWithParams } from '../../services/servico';
import { SearchI, SearchII } from '../../interfaces/servico';
import { IoMdSearch } from 'react-icons/io';
import getCookie from '../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxTitle: {
    display: 'flex',
    width: '100%',
    marginTop: 40,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    color: '#333333'
  },
  boxInfoService: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  infoService: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 680,
    height: 163,
    borderRadius: 4,
  },
  titleService: {
    margin: '26px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    textAlign: 'center',
  },
  detailsService: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 8,
  },
  ticket: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 30,
  },
  boxEvaluation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

  },
  evaluation: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary,
    padding: 60,
    maxWidth: 750,
    width: '100%',
    marginTop: 100,
  },
  infoSlider: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  evaluationService: {
    with: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 18,
    color: '#333333',
    textAlign: 'center',
  },
  boxRating: {
    margin: '16px 0px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonRating: {
    padding: 14,
    margin: '0px 8px',
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInfoRating: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    color: '#706E7A',
    marginTop: 20,
  },
  description: {
    width: '100%',
    marginTop: 20,
    maxWidth: 600,
    borderRadius: 4,
    minHeight: '142px auto',
    margin: '0px 10px',
    border: '1px solid #E1E6EF',
    fontFamily: 'Roboto',
  },
  buttonNext: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,

    borderRadius: 4,
    textTransform: 'uppercase'
  },
  buttonReturn: {
    fontFamily: 'Roboto',
    width: 94,
    fontSize: 14,
    fontWeight: 500,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase',
    backgroundColor: '#fff',
    marginRight: 10,
  },
  boxActions: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  input: {
    color: '#333',
  },
  boxSuccess: {
    width: '100%',
    maxWidth: 680,
    marginTop: 60,
    marginBottom: 40,
    margin: 'auto',
    borderRadius: 4,
    flexDirection: 'column',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  boxIcon: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 16,
  },
  subTitleInfo: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    margin: '0px 10px',
  },
  actions: {
    flexWrap: 'wrap',
    with: '100%',
    display: 'flex',
    padding: '0px 40px',
    marginBottom: 40,
  },
  buttonSchedule: {
    width: 185,
    fontSize: 14,
    textTransform: 'uppercase',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: 4,
    padding: 10,
    margin: '10px 0px',
  },
  buttonReturnAll: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    margin: '10px 0px',
  },
  iconStar: {
    height: 24,
    width: 24,
  },
  inputBoxSearch: {
    maxWidth: 582,
    width: "100%",
    height: 48,
    border: `1px solid ${theme.palette.primary.main}`,
    outline: "none",
    paddingLeft: 16,
    fontFamily: "Mulish",
    fontSize: 14,
    borderRadius: 0,
    display: "flex",
  },
  inputSearch: {
    maxWidth: 582,
    width: "100%",
    height: 40,
    border: `none`,
    outline: "none",
    paddingLeft: 16,
    fontFamily: "Mulish",
    fontSize: 14,
    borderRadius: 0,
  },
  icon: {
    width: 24,
    height: 24,
  },
  texts: {
    width: "100%",
    textAlign: "center",
  },
  optionsList: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #DADCE0",
    height: "48px",
    borderLeftWidth: "2px",
    borderLeftColor: `${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionsListText: {
    fontSize: "14px",
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Mulish",
  },
  iconArrow: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  titleDispo: {
    fontSize: "16px",
    fontStyle: "Molish",
    color: "#373F41",
    marginTop: 10,
    fontWeight: 700,
    lineHeight: "20px",
  },
  boxButtonClose: {
    width: 42,
    height: 42,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  boxSelectProfile: {
    display: "flex",
    marginTop: 20,
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  boxLabelRadio: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    border: "1px solid #E1E6EF",
    padding: 5,
    flex: 1,
    margin: "5px 10px",
    paddingLeft: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  boxIconProfile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    fontWeight: 700,
  },
}));

export default function ServiceSearchEvaluation(): JSX.Element {
  const matches = useMediaQuery("(min-width:700px)");
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const token: string | null = getCookie('gov_access_token_sso');
  const [textSearch, setTextSearch] = useState<string>("");
  const [listSearch, setListSearch] = useState<SearchII>();
  const linksBreadcrumb = [
    {
      name: "Meus Agendamentos",
      redirectTo: "/workspace/meus_agendamentos"
    },
    {
      name: "Avaliação de atendimento presencial",
      redirectTo: "#",
    }
  ];

  const getSearch = async (search: string, page: number) => {
    const typeUser = await getCookie("perfil");
    const tokenSiseci: string | null = sessionStorage.getItem(
      "gov_access_token_siseci"
    );
    setListSearch(undefined);
    setLoading(true);
    try {
      const { data } = await searchServiceWithParams(
        {
          ativo: true,
          page,
          items_size: 4,
          publico_especifico: typeUser,
          search,
        },
        tokenSiseci
      );
      setListSearch(data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  const handleRemoveServiceSelected = () => {
    setListSearch(undefined);
    setTextSearch("");
  };
  const handleGetServiceSelected = (service: SearchI) => {
    if (service) {
      history.push(`/workspace/buscar_servico/avaliar/${service.slug}`);
    }
  };

  const handleSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (textSearch) {
      getSearch(textSearch, 1);
    }
  };

  return (
    <>
      {token && <Breadcrumb links={linksBreadcrumb} />}
      <Container>
        <Box className={classes.boxTitle}>
          <Typography className={classes.title}>Avaliação de atendimento presencial</Typography>
        </Box>
        <Box className={classes.boxEvaluation}>
          <Box className={classes.evaluation}>
            <Box className={classes.texts}>
              <Typography className={classes.title}>Faça uma busca pelo serviço</Typography>
              <Typography className={classes.textInfoRating}>Busque pelo serviço que deseja avaliar</Typography>
            </Box>
            <div id="input-unidade">
              <form
                style={{
                  display: "flex",
                  maxWidth: 1052,
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: !matches ? "column" : "row",
                  alignItems: !matches ? "center" : "normal",
                  marginTop: "15px",
                }}
                onSubmit={handleSubmitSearch}
              >
                <Box className={classes.inputBoxSearch}>
                  <input
                    onChange={(e) => {
                      setListSearch(undefined);
                      setTextSearch(e.target.value);
                    }}
                    className={classes.inputSearch}
                    placeholder="Digite aqui o que procura..."
                    value={textSearch}
                  />
                  {textSearch && (<Box
                    className={classes.boxButtonClose}
                    onClick={() => {
                      handleRemoveServiceSelected();
                    }}
                  >
                    <MdClose
                      className={classes.icon}
                      style={{ color: "gray" }}
                    />
                  </Box>)}

                </Box>
                <Button
                  style={{
                    borderRadius: 0,
                    marginTop: matches ? 0 : 20,
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <IoMdSearch
                    className={classes.icon}
                    style={{ color: "#FFFFFF" }}
                  />
                </Button>
              </form>
            </div>
            {loading ? (
              <Box style={{ textAlign: "center", marginTop: 10 }}>
                <CircularProgress
                  style={{ width: 25, height: 25, textAlign: "center" }}
                  color="primary"
                />
              </Box>
            ) : (
              ""
            )}
            {listSearch && listSearch?.results.length > 0 ? (
              <>
                <Typography className={classes.titleDispo}>
                  Serviços disponíveis
                </Typography>
                {listSearch &&
                  listSearch.results.map((search) => (
                    <Box
                      className={classes.optionsList}
                      key={search.id}
                      onClick={() => handleGetServiceSelected(search)}
                    >
                      <Typography className={classes.optionsListText}>
                        {" "}
                        {search?.titulo}
                      </Typography>
                      <MdArrowForward className={classes.iconArrow} />
                    </Box>
                  ))}
              </>
            ) : ("")}
          </Box>
        </Box>
      </Container>
    </>
  )
}