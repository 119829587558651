import {
  Box, Card, CircularProgress, Container
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { LogoData } from '../Header';
import React, { useEffect, useState } from "react";
import { listLogos } from '../../services/noticias';
import getCookie from '../../utils/getCookies';

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: '100%',
    height: 96,
    backgroundColor: theme.palette.primary.main,
    marginTop: '150px'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '100%',
    alignItems: 'center'
  },
  version: {
    color: '#fff',
    fontWeight: 500,
    fontSize: 12
  },
  logoImg: {
    width: '104.14px',
    height: '48.72px',
  },
}));

export default function Footer(): JSX.Element {
  const classes = useStyles();
  const [logo, setLogo] = useState<LogoData[]>();
  const [loading, setLoading] = useState(false);

  const token: string | null = getCookie("gov_access_token_sso");
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      setLoading(true);
      const { data: Logos } = await listLogos();

      setLogo(Logos);
    }  finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token && tokenSiseci) {
      getUserData(token, tokenSiseci);
    }
  }, [token, tokenSiseci]);

  return (
    <Box className={classes.main}>
      <Container className={classes.container}>
          <Box className={classes.version}>
           Versão 1.22.2
          </Box>
          <Box>
         {loading ?(
            <CircularProgress
              style={{
                width: 25,
                height: 25,
                color: "#fff",
              }}
            />
         ): (
          <img
              alt={logo && logo[1].titulo}
              src={logo && logo[1].url_imagem}
              className={classes.logoImg}
            /> 
         ) }
          </Box>
      </Container>
    </Box>
  );
}
