import React, { ReactElement, useEffect } from 'react';
import {
  Fab,
  Theme,
  useScrollTrigger,
  Zoom,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { useHistory, useLocation } from 'react-router-dom';
import Licence from '../../Licence';
import { sendCodeForSISECI } from '../../../services/auth';
import { setCookie } from '../../../utils/setCookie';

interface Props {
  children: JSX.Element;
  // eslint-disable-next-line react/require-default-props
  window?: () => Window;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

export default function BlankLayout(props: Props): ReactElement {
  const { children } = props;
  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const url = (window as any).location.search;
    const { pathname } = location;
    const query = new URLSearchParams(url);
    const code: any = query.get('code');
    if (code) {
      setCookie('pathname_redirect_gov', pathname);
      sendCodeForSISECI(code, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {children}
    </>
  );
}
