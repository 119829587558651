import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Container,
  Card,
  CardContent,
  IconButton,
} from "@material-ui/core";
import { MdArrowBack, MdVpnKey, MdContentPaste } from "react-icons/md";
import { Breadcrumb } from "../../Components";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxAccess: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    minHeight: 300,
    padding: 20,
  },
  cardContent: {
    display: "flex",

    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 300,
  },
  iconAccess: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF",
    borderRadius: 4,
    width: 50,
    height: 50,
    padding: 7,
    marginBottom: 20,
  },
  textCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 10,
    fontSize: 16,
    letterSpacing: 0.3,
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  subTextCard: {
    fontFamily: "Roboto",
    color: "#373F41",
    marginTop: 5,
    marginBottom: 20,
    fontSize: 16,
    letterSpacing: 0.3,
    fontWeight: "normal",
  },
  linkCard: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    fontWeight: "normal",
  },
}));

type Props = {
  history: any;
};

export default function Privacy({ history }: Props): JSX.Element {
  const classes = useStyles();
  const linksBreadcrumb = [
    {
      name: "Privacidade",
      redirectTo: "#",
    },
  ];
  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push('/workspace/')}>
                  <MdArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Privacidade
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <MdVpnKey className={classes.iconAccess} />
                <Typography className={classes.textCard}>Permissões</Typography>
                <Typography className={classes.subTextCard}>
                  Veja quem tem acesso aos seus dados e quais dados foram
                  disponibilizados por você para acessar algum serviço. Controle
                  também as permissões concedidas por você, desativando ou
                  reativando o acesso aos seus dados.
                </Typography>
                <Link to="/workspace/permissoes" className={classes.linkCard}>
                  Gerenciar lista de permissões
                </Link>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
            <Card className={classes.boxAccess}>
              <CardContent className={classes.cardContent}>
                <MdContentPaste className={classes.iconAccess} />
                <Typography className={classes.textCard}>
                  termos de aceite
                </Typography>
                <Typography className={classes.subTextCard}>
                  Aqui você tem acesso a informações do funcionamento do serviço,
                  qual a base legal aplicada a esta prestação,
                  sua responsabilidade ao utilizá-lo e também quais as
                  responsabilidades da Administração Pública enquanto
                  provedora do serviço.
                </Typography>
                <Link to="/workspace/termos" className={classes.linkCard}>
                  Consultar lista de termos
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
