import { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { apisiseci } from './api';

export const checarHorarios = (
  date: Date,
  unity: string,
  serviceID: number
): Promise<AxiosResponse> => {
  const dateScheduler = format(date, 'yyyy-MM-dd');

  return apisiseci.get(
    `agendamento/checar_horarios/?data=${dateScheduler}&servico=${serviceID}&unidade=${unity}`
  );
};

export const agendar = (
  token: string,
  cpf: string,
  servico: number,
  unidade: string,
  setor: string,
  tipo: string,
  date: Date,
  hora: string,
  para_dependente?: boolean
): Promise<AxiosResponse> => {
  const data = format(date, 'yyyy-MM-dd');
  return apisiseci.post(
    'agendamento/agendar/',
    {
      cpf,
      servico,
      unidade,
      setor,
      tipo,
      data,
      hora,
      para_dependente,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const incluirDependente = (
  id_agendamento: number,
  cpf: string,
  nome: string,
  data_nascimento: Date,
  cpf_solicitante: string,
  grau_parentesco: string,
  token: string
): Promise<AxiosResponse> => {
  const data = format(data_nascimento, 'yyyy-MM-dd');
  return apisiseci.post(
    'agendamento/dependente/',
    {
      agendamento: id_agendamento,
      cpf,
      nome,
      data_nascimento: data,
      cpf_solicitante,
      grau_parentesco,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export interface IParamsHistory {
  status: string;
}

export const historico = (
  token: string,
  page: number,
  params?: IParamsHistory
): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/historico/?page=${page}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params,
  });

export const getHistorico = (
  token: string,
  id: number
): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/historico/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const cancelar = (token: string, id: number): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/cancelar/${id}/`,
    {
      id,
      status: 'Cancelado',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const cancelar_internos = (token: string, id: number): Promise<AxiosResponse> =>
  apisiseci.put(
    `agendamento/cancelar_internos/${id}/`,
    {
      id,
      status: 'Cancelado',
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const feriados = (): Promise<AxiosResponse> =>
  apisiseci.get('cms/feriados/');

export const avaliacao_presencial = (
  hash: string,
  pendente: boolean,
  avaliacao_consumo: number,
  avaliacao_atendimento: number,
  comentario_consumo: string,
  comentario_atendimento: string,
  avaliacao_resolucao_problema?: number
): Promise<AxiosResponse> =>
  apisiseci.put(`agendamento/avaliar_presencial/${hash}/`, {
    id: hash,
    pendente,
    avaliacao_consumo,
    avaliacao_atendimento,
    comentario_consumo,
    comentario_atendimento,
    avaliacao_resolucao_problema
  });

export const avaliacao_geral = ({
  token,
  servico,
  avaliacao_informacao,
  comentario_informacao,
  avaliacao_consumo,
  comentario_consumo,
  avaliacao_atendimento,
  comentario_atendimento,
}: {
  token?: string;
  servico: number;
  avaliacao_informacao: number;
  comentario_informacao: string;
  avaliacao_consumo: number;
  comentario_consumo: string;
  avaliacao_atendimento: number;
  comentario_atendimento: string;
}
): Promise<AxiosResponse> => {
  const options = token ? {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  } : {};
  return apisiseci.post(`cms/avaliacao/`, {
    servico,
    avaliacao_informacao,
    comentario_informacao,
    avaliacao_consumo,
    comentario_consumo,
    avaliacao_atendimento,
    comentario_atendimento,
  }, options)
};

export const getAvaliacaoPresencial = (hash: string): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/avaliacao_presencial/?id=${hash}`);

export const avaliacao_interno = (
  hash: string,
  pendente: boolean,
  avaliacao_consumo: number,
  avaliacao_atendimento: number,
  comentario_consumo: string,
  comentario_atendimento: string,
  avaliacao_resolucao_problema?: number
): Promise<AxiosResponse> =>
  apisiseci.put(`agendamento/avaliar_interno/${hash}/`, {
    id: hash,
    pendente,
    avaliacao_consumo,
    avaliacao_atendimento,
    comentario_consumo,
    comentario_atendimento,
    avaliacao_resolucao_problema
  });

export const getAvaliacaoInterno = (hash: string): Promise<AxiosResponse> =>
  apisiseci.get(`agendamento/avaliacao_interno/?id=${hash}`);


export const getQrCodeServices = ({ id }: {id: string}): Promise<AxiosResponse> => {  
  return apisiseci.get(`cms/qrcode_avaliacao/?id=${id}`)
}