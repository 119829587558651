import React, { useEffect, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Popover,
  useMediaQuery,
} from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {
  MdExpandMore,
  MdBusiness,
  MdFace,
  MdBusinessCenter,
} from "react-icons/md";

import { useHistory } from "react-router-dom";
import { UserData } from "../../interfaces/user";
import { setCookie } from "../../utils/setCookie";
import getCookie from "../../utils/getCookies";
import ModalProfile from "../ModalProfile";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "100%",
    height: 72,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
    background: "#FFFFFF",
  },
  navContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  menuItem: {
    color: "#737B7D",
    width: 200,
    height: 45,
  },
  textMenuItem: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "normal",
    marginLeft: 10,
    margin: "auto",
  },
  btnProfile: {
    height: 45,
    textTransform: "capitalize",
    textAlign: "left",
    justifyContent: "space-between",
    marginLeft: 10,
    color: theme.palette.text.primary,
  },
  textBtnProfile: {
    display: "flex",
    fontSize: theme.typography.pxToRem(16),
    fontWeight: "bold",
    marginLeft: 10,
    alignItems: "center",
    justifyContent: "space-between",
  },
  textLabelProfile: {
    color: "#212121",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontSize: theme.typography.pxToRem(16),
    textTransform: "capitalize",
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    }
  },
  icon: {
    width: 24,
    height: 24,
  },
}));

export default function SelectProfile(): JSX.Element {
  const classes = useStyles();
  const matchesMobile = useMediaQuery("(max-width:660px)");
  const history = useHistory();
  const [currentProfile, setCurrentProfile] = useState(getCookie("perfil"));
  const [userData, setUserData] = useState<UserData | null>(null);
  const [openModalProfile, setOpenModalProfile] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    const data = getCookie("gov_user_data");
    setUserData(data ? JSON.parse(data) : null);

    if (!currentProfile) {
      setOpenModalProfile(true);
    }
  }, [currentProfile]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChangeProfile = (profile: string) => {
    setCookie("perfil", profile);
    setCurrentProfile(profile);
    history.go(0);
  };

  const onChangeProfile = () => {
    setOpenModalProfile(!openModalProfile);
  };

  const dataMenuBtn = () => {
    const cidadao = (
      <Typography className={classes.textBtnProfile}>
        {matchesMobile ? (
          <MdFace className={classes.icon} />
        ) : (
          <>
            <MdFace className={classes.icon} />
            Cidadão
          </>
        )}
      </Typography>
    );

    const servidor = (
      <Typography className={classes.textBtnProfile}>
        {matchesMobile ? (
          <MdBusinessCenter className={classes.icon} />
        ) : (
          <>
            <MdBusinessCenter className={classes.icon} />
            Servidor
          </>
        )}
      </Typography>
    );

    const empresa = (
      <Typography className={classes.textBtnProfile}>
        {matchesMobile ? (
          <MdBusiness className={classes.icon} />
        ) : (
          <>
            <MdBusiness className={classes.icon} />
            Empresa
          </>
        )}
      </Typography>
    );

    if (currentProfile) {
      switch (currentProfile) {
        case "Cidadão":
          return cidadao;

        case "Servidor":
          return servidor;

        case "Empresa":
          return empresa;

        default:
          return cidadao;
      }
    }
    return cidadao;
  };

  return (
    <Box>
      <Typography variant="overline" className={classes.textLabelProfile}>
        Perfil:
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleClick}
        className={classes.btnProfile}
      >
        {dataMenuBtn()}
        <MdExpandMore className={classes.icon} />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box>
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              handleChangeProfile("Cidadão");
            }}
          >
            <MdFace className={classes.icon} />
            <Typography className={classes.textMenuItem}>
              Cidadão
            </Typography>
          </MenuItem>
          <Divider />

          {userData && (
            <>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  handleChangeProfile("Servidor");
                }}
              >
                <MdBusinessCenter className={classes.icon} />
                <Typography className={classes.textMenuItem}>
                  Servidor
                </Typography>
              </MenuItem>
              <Divider />
            </>
          )}

          <MenuItem
            className={classes.menuItem}
            onClick={() => handleChangeProfile("Empresa")}
          >
            <MdBusiness className={classes.icon} />
            <Typography className={classes.textMenuItem}>
              Empresa
            </Typography>
          </MenuItem>
        </Box>
      </Popover>
      <ModalProfile open={openModalProfile} onChange={onChangeProfile} handleChangeProfile={handleChangeProfile} />
    </Box>
  );
}
