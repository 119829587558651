/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  IconButton,
  makeStyles,
  Theme,
  Box,
  Link,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { MdMoreVert, MdDelete, MdEvent, MdVisibility } from "react-icons/md";
import {
  getAtividades,
  removeFavoriteService,
} from "../../../../../../services/servico";
import ModalAttendenceOnline from "../../../../../../Components/SearchServices/components/ModalAttendenceOnline";
import ModalShedulingPresential from "../../../../../../Components/SearchServices/components/ModalShedulingPresential";
import { SearchI } from "../../../../../../interfaces/servico";
import { getService } from "../../../../../../services/servico";
import { useStateValue } from "../../../../store/stateProvider";
import { actionType } from "../../../../store/reducer";
import getCookie from "../../../../../../utils/getCookies";

const useStyles = makeStyles((theme: Theme) => ({
  itemMenuAgendar: {
    display: "flex",
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    textDecoration: "none",
    padding: "5px 0px",
    "&:hover": {
      textDecoration: "none",
    },
  },
  itemMenuRemover: {
    color: "#264259",
    fontFamily: "Roboto",
    letterSpacing: 0.3,
    fontWeight: "normal",
    paddingTop: 5,
    paddingBottom: 5,
  },
  boxActionsIcon: {
    width: 40,
    height: 32,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textLink: {
    fontSize: 16,
    fontWeight: 500,
    color: "#333333",
    fontFamily: "Roboto",
  },
  icon: {
    width: 16,
    height: 16,
  },
}));

interface Props {
  service_slug: string;
  categoria_slug: string;
  favoriteId: number;
  agendavel: boolean;
  online: boolean;
}

export default function Menus({
  service_slug,
  favoriteId,
  agendavel,
  online,
  categoria_slug,
}: Props) {
  const classes = useStyles();
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [serviceSelected, setServiceSelected] = useState<SearchI | undefined>();
  const [showModalAttendenceOnline, setShowModalAttendenceOnline] =
    useState<boolean>(false);
  const [showModalShedulingPresential, setShowModalShedulingPresential] =
    useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [_, dispatch] = useStateValue();
  const token: string | null = getCookie('gov_access_token_sso');
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAtividadesAll = async () => {
    if (tokenSiseci) {
      try {
        const { data } = await getAtividades(tokenSiseci);

        await dispatch({
          type: actionType.GET_FAVORITES,
          favorites: data.favoritos,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handeRemove = async (favoriteId: number) => {
    if (token) {
      try {
        setLoading(true);
        await removeFavoriteService(favoriteId, token);
        await getAtividadesAll();
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleModalOnline = async (slug_text: string) => {
    if (tokenSiseci) {
      setLoading(true);
      try {
        const { data } = await getService(slug_text);
        setServiceSelected(data.results[0]);
      } catch (error) {
        console.log(error);
      }
      setAnchorEl(null);
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box className={classes.boxActionsIcon}>
        <IconButton
          aria-label="comments"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MdMoreVert className={classes.icon} style={{ color: "#fff" }} />
        </IconButton>
      </Box>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {loading ? (
          <MenuItem className={classes.itemMenuAgendar}>
            <CircularProgress
              color="primary"
              style={{ width: 25, height: 25 }}
            />
          </MenuItem>
        ) : (
          <React.Fragment>
            <MenuItem>
              <Link
                href={`/${categoria_slug}/${service_slug}`}
                target="_new"
                className={classes.itemMenuAgendar}
              >
                <MdVisibility
                  className={classes.icon}
                  style={{ marginRight: 10 }}
                />
                <Typography className={classes.textLink}>
                  Visualizar serviço
                </Typography>
              </Link>
            </MenuItem>
            {agendavel && (
              <MenuItem>
                <Link
                  href="#"
                  className={classes.itemMenuAgendar}
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    setShowModalShedulingPresential(true);
                    handleModalOnline(service_slug);
                  }}
                >
                  <MdEvent
                    className={classes.icon}
                    style={{ marginRight: 10 }}
                  />
                  <Typography className={classes.textLink}>
                    Agendamento presencial
                  </Typography>
                </Link>
              </MenuItem>
            )}
            {online && (
              <MenuItem>
                <Link
                  href="#"
                  className={classes.itemMenuAgendar}
                  onClick={(event: React.SyntheticEvent) => {
                    event.preventDefault();
                    setShowModalAttendenceOnline(true);
                    handleModalOnline(service_slug);
                  }}
                >
                  <span style={{ marginRight: 10 }} className="material-icons">
                    support_agent
                  </span>
                  <Typography className={classes.textLink}>
                    Agendamento online
                  </Typography>
                </Link>
              </MenuItem>
            )}
            <MenuItem
              onClick={async () => {
                await handeRemove(favoriteId);
                handleClose();
              }}
              className={classes.itemMenuRemover}
            >
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <React.Fragment>
                  <MdDelete
                    className={classes.icon}
                    style={{ marginRight: 10, color: "#F03D3D" }}
                  />
                  <Typography className={classes.textLink}>
                    Remover favorito
                  </Typography>
                </React.Fragment>
              )}
            </MenuItem>
          </React.Fragment>
        )}
      </Menu>

      {serviceSelected && (
        <ModalAttendenceOnline
          handleClose={() => {
            setShowModalAttendenceOnline(false);
          }}
          open={showModalAttendenceOnline}
          type="Video"
          serviceSelected={serviceSelected}
          setShowResult={() => false}
        />
      )}
      {serviceSelected && (
        <ModalShedulingPresential
          handleClose={() => {
            setShowModalShedulingPresential(false);
          }}
          open={showModalShedulingPresential}
          serviceSelected={serviceSelected}
          setShowResult={() => false}
        />
      )}
    </Box>
  );
}
