
import { Box, CircularProgress, Collapse, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, createStyles, makeStyles, useMediaQuery, useTheme, withStyles } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import getCookie from "../../../../utils/getCookies";
import { RespostasI, RespostasII } from "../../../../interfaces/form-flow";
import { getRequests } from "../../../../services/form-flow";
import { extractDateFormat } from "../../../../utils/dateFormat";
import { HiOutlineClipboardList } from "react-icons/hi";
import { MdMoreVert } from "react-icons/md";
import RequestModal from "./RequestModal";
import { IoIosArrowDown } from "react-icons/io";

const useStyles = makeStyles((theme: Theme) => ({
  boxActionsIcon: {
    width: 40,
    height: 32,
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 1,
  },
  icon: {
    width: '32px',
    height: '32px',
    color: theme.palette.primary.main,
  },
  iconResponsive: {
    width: '24px',
    height: '24px',
  },
  iconButton: {
    width: '16px',
    height: '16px',
    color: theme.palette.primary.main,
  },
  title: {
    margin: 0,
    color: '#212121',
    fontWeight: 700,
    fontSize: '18px'
  }
}));

export default function RecommendationServices(): JSX.Element {
  const classes = useStyles();

  const [loading, setLoading] = useState<boolean>(true);
  const tokenSiseci: string | null = getCookie('gov_access_token_sso');
  const [listRequests, setListRequests] = useState<RespostasII>();
  const [openModal, setOpenModal] = useState<RespostasI>()
  const currentProfile: string | "Cidadão" | "Servidor" | "Empresa" =
    getCookie("perfil") || "Cidadão";
  const isMobile = useMediaQuery("(max-width:600px)");

  const getRequestsList = async ({ page }: { page?: number }) => {
    setLoading(true);
    try {
      let user = JSON.parse(getCookie('gov_user_data') || '{}');
      if (user?.cpf) {
        const { data } = await getRequests({ page, cpf: user.cpf });
        setListRequests(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    if (tokenSiseci) {
      getRequestsList({ page: 1 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleModal = (request?: RespostasI) => {
    setOpenModal(request !== undefined ? request : undefined)
  }

  const StyledTableCell = withStyles((theme: Theme) =>
    createStyles({
      head: {
        fontWeight: 700,
        fontSize: 14,
        fontFamily: 'Roboto',
        color: '#212121'
      },
      body: {
        fontSize: 12,
        fontFamily: 'Roboto',
        color: '#212121'
      },
    })
  )(TableCell);

  const matchesMobile = useMediaQuery("(min-width:600px) and (max-width:1000px)");
  const [checked, setChecked] = React.useState(true);
  const theme = useTheme();
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const TabelaComponente = () => (
    <Table aria-label="customized table">
      <TableHead >
        <TableRow >
          <StyledTableCell style={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}>Título da Solicitação</StyledTableCell>
          {!isMobile && <StyledTableCell style={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}>Órgão</StyledTableCell>}
          {!isMobile && <StyledTableCell style={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}>Data da Solicitação</StyledTableCell>}
          <StyledTableCell style={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}>N° do Protocolo</StyledTableCell>
          <StyledTableCell style={{ borderBottom: `1px solid ${theme.palette.primary.main}` }}>Ação</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && <CircularProgress />}
        {listRequests?.results.length > 0 ? (
          listRequests.results.map((request) => (
            <Fragment >
              <TableRow>
                <StyledTableCell>
                  {request.flow.titulo}
                </StyledTableCell>
                {!isMobile && <StyledTableCell>
                  {request.orgao?.sigla}
                </StyledTableCell>
                }
                {!isMobile && <StyledTableCell>
                  {`${extractDateFormat(request?.created_at)}`}
                </StyledTableCell>}
                <StyledTableCell>
                  {request.protocolo ? request.protocolo : '-'}
                </StyledTableCell>
                <StyledTableCell>
                  <Box className={classes.boxActionsIcon}>
                    <IconButton
                      aria-label="comments"
                      aria-haspopup="true"
                      onClick={() => handleModal(request)}
                    >
                      <MdMoreVert className={classes.iconButton} style={{ color: "#fff" }} />
                    </IconButton>
                  </Box>
                </StyledTableCell>
              </TableRow>
            </Fragment>
          ))
        ) : (
          <TableRow>
            <StyledTableCell colSpan={5} style={{ textAlign: 'center' }}>
           {!loading && 'Não há Solicitações'}
            </StyledTableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );

  return (
    <>
      <TableContainer style={{ background: '#fff', border: !matchesMobile && '1px solid #DDDDDD', borderRadius: '8px' }}>
        {(!matchesMobile && !isMobile) &&
          <Box display="flex" margin="16px" style={{ gap: '8px', alignItems: 'center' }}>
            <HiOutlineClipboardList className={classes.icon} />
            <span className={classes.title}>Minhas Solicitações</span>
          </Box>
        }

        {isMobile &&
          <Box display="flex" padding="24px" style={{ gap: '8px', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer', backgroundColor: checked && theme.palette.primary.main }} onClick={() => handleChange()}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <HiOutlineClipboardList className={classes.iconResponsive} color={checked ? '#fff' : theme.palette.primary.main} />
              <span style={{ color: checked ? '#fff' : "#212121", marginLeft: '16px', fontSize: '16px', fontWeight: 700 }} >Minhas Solicitações</span>
            </div>
            <IoIosArrowDown fontSize={24} color={checked ? '#fff' : theme.palette.primary.main} style={{ transform: checked ? 'rotate(180deg)' : 'none' }} />
          </Box>
        }

        {isMobile ? (
          <Collapse in={checked}>
            <TabelaComponente />
          </Collapse>
        ) : (
          <TabelaComponente />
        )}

        {openModal && <RequestModal data={openModal} handleModal={handleModal} />}
      </TableContainer>
    </>
  );
}
