import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const apisso = axios.create({
  baseURL: atob(process.env.REACT_APP_API_SSO) ?? '',
});

/* const ssoMti = axios.create({
  baseURL: atob(process.env.REACT_APP_SSO_MTI) ?? '',
}); */

const apisiseci = axios.create({
  baseURL: atob(process.env.REACT_APP_API_SISECI) ?? '',
});

const apinoticias = axios.create({
  baseURL: atob(process.env.REACT_APP_API_NOTICIAS) ?? '',
});

const apiforFlow = axios.create({
  baseURL: atob(process.env.REACT_APP_API_FORM_FLOW) ?? 'https://newforms.digital-gov.com/api/',
});

apisiseci.defaults.headers.common.Authorization = atob(process.env.REACT_APP_API_SISECI_KEY) ?? '';
apisso.defaults.headers.common.Authorization = atob(process.env.REACT_APP_API_SSO_KEY) ?? '';
apinoticias.defaults.headers.common.Authorization = atob(process.env.REACT_APP_API_NOTICIAS_KEY) ?? '';

export {
  apisso, apisiseci,/*  ssoMti, */ apinoticias, apiforFlow
};


