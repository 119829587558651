import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Container,
  Button,
  CircularProgress,
  Radio,
  SvgIcon
} from '@material-ui/core';
import { Breadcrumb, ModalNotAuth } from '../../Components';
import { useHistory, useParams } from 'react-router-dom';
import { getService } from '../../services/servico';
import getCookie from '../../utils/getCookies';


const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxTitle: {
    display: 'flex',
    width: '100%',
    marginTop: 40,
  },
  title: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 24,
    color: '#333333'
  },
  boxInfoService: {
    marginTop: 80,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  infoService: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 680,
    height: 163,
    borderRadius: 4,
  },
  titleService: {
    margin: '26px 0px 8px 0px',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 20,
    textAlign: 'center',
  },
  detailsService: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 8,
  },
  ticket: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 30,
  },
  boxEvaluation: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

  },
  evaluation: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    borderRadius: 4,
    backgroundColor: theme.palette.text.primary,
    padding: 60,
    maxWidth: 750,
    width: '100%',
    marginTop: 100,
  },
  infoSlider: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center'
  },
  evaluationService: {
    with: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 18,
    color: '#333333',
    textAlign: 'center',
  },
  boxRating: {
    margin: '16px 0px 24px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  buttonRating: {
    padding: 14,
    margin: '0px 8px',
    borderRadius: 4,
    border: '1px solid #E1E6EF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textInfoRating: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    color: '#706E7A',
    marginTop: 20,
  },
  description: {
    width: '100%',
    marginTop: 20,
    maxWidth: 600,
    borderRadius: 4,
    minHeight: '142px auto',
    margin: '0px 10px',
    border: '1px solid #E1E6EF',
    fontFamily: 'Roboto',
  },
  buttonNext: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,

    borderRadius: 4,
    textTransform: 'uppercase'
  },
  buttonReturn: {
    fontFamily: 'Roboto',
    width: 94,
    fontSize: 14,
    fontWeight: 500,
    height: 44,
    borderRadius: 4,
    textTransform: 'uppercase',
    backgroundColor: '#fff',
    marginRight: 10,
  },
  boxActions: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  input: {
    color: '#333',
  },
  boxSuccess: {
    width: '100%',
    maxWidth: 680,
    marginTop: 60,
    marginBottom: 40,
    margin: 'auto',
    borderRadius: 4,
    flexDirection: 'column',
    backgroundColor: theme.palette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  check: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  boxIcon: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  titleInfo: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    marginBottom: 16,
  },
  subTitleInfo: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'center',
    margin: '0px 10px',
  },
  actions: {
    flexWrap: 'wrap',
    with: '100%',
    display: 'flex',
    padding: '0px 40px',
    marginBottom: 40,
  },
  buttonSchedule: {
    width: 185,
    fontSize: 14,
    textTransform: 'uppercase',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    borderRadius: 4,
    padding: 10,
    margin: '10px 0px',
  },
  buttonReturnAll: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.primary.main,
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    margin: '10px 0px',
  },
  iconStar: {
    height: 24,
    width: 24,
  },
  inputBoxSearch: {
    maxWidth: 582,
    width: "100%",
    height: 48,
    border: `1px solid ${theme.palette.primary.main}`,
    outline: "none",
    paddingLeft: 16,
    fontFamily: "Mulish",
    fontSize: 14,
    borderRadius: 0,
    display: "flex",
  },
  inputSearch: {
    maxWidth: 582,
    width: "100%",
    height: 40,
    border: `none`,
    outline: "none",
    paddingLeft: 16,
    fontFamily: "Mulish",
    fontSize: 14,
    borderRadius: 0,
  },
  icon: {
    width: 24,
    height: 24,
  },
  texts: {
    width: "100%",
    textAlign: "center",
  },
  optionsList: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #DADCE0",
    height: "48px",
    borderLeftWidth: "2px",
    borderLeftColor: `${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionsListText: {
    fontSize: "14px",
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Mulish",
  },
  iconArrow: {
    color: theme.palette.primary.main,
    width: 24,
    height: 24,
  },
  titleDispo: {
    fontSize: "16px",
    fontStyle: "Molish",
    color: "#373F41",
    marginTop: 10,
    fontWeight: 700,
    lineHeight: "20px",
  },
  boxButtonClose: {
    width: 42,
    height: 42,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      cursor: "pointer",
    },
  },
  boxSelectProfile: {
    display: "flex",
    marginTop: 20,
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  boxLabelRadio: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    border: "1px solid #E1E6EF",
    padding: 5,
    flex: 1,
    margin: "5px 10px",
    paddingLeft: 20,
    "&:hover": {
      cursor: "pointer",
    },
  },
  boxIconProfile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    fontWeight: 700,
  },
}));


const AccountCheckIcon = (props) => {
  return (
    <SvgIcon width="20" height="17" viewBox="0 0 20 17" fill="none" {...props}>
      <path d="M18.1 8.5L19.5 9.91L12.97 16.5L9.5 13L10.9 11.59L12.97 13.67L18.1 8.5ZM7 13L10 16H0V14C0 11.79 3.58 10 8 10L9.89 10.11L7 13ZM8 0C9.06087 0 10.0783 0.421427 10.8284 1.17157C11.5786 1.92172 12 2.93913 12 4C12 5.06087 11.5786 6.07828 10.8284 6.82843C10.0783 7.57857 9.06087 8 8 8C6.93913 8 5.92172 7.57857 5.17157 6.82843C4.42143 6.07828 4 5.06087 4 4C4 2.93913 4.42143 1.92172 5.17157 1.17157C5.92172 0.421427 6.93913 0 8 0V0Z" />
    </SvgIcon >);
}
const IncognitoIcon = (props) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none"  {...props}>
      <path d="M15.06 12C13.2 12 11.64 13.33 11.24 15.1C10.29 14.69 9.42 14.8 8.76 15.09C8.35 13.31 6.79 12 4.94 12C2.77 12 1 13.79 1 16C1 18.21 2.77 20 4.94 20C7 20 8.68 18.38 8.84 16.32C9.18 16.08 10.07 15.63 11.16 16.34C11.34 18.39 13 20 15.06 20C17.23 20 19 18.21 19 16C19 13.79 17.23 12 15.06 12ZM4.94 18.86C3.38 18.86 2.13 17.58 2.13 16C2.13 14.42 3.39 13.14 4.94 13.14C6.5 13.14 7.75 14.42 7.75 16C7.75 17.58 6.5 18.86 4.94 18.86ZM15.06 18.86C13.5 18.86 12.25 17.58 12.25 16C12.25 14.42 13.5 13.14 15.06 13.14C16.62 13.14 17.88 14.42 17.88 16C17.88 17.58 16.61 18.86 15.06 18.86ZM20 9.49999H0V11H20V9.49999ZM13.53 1.62999C13.31 1.13999 12.75 0.879994 12.22 1.04999L10 1.78999L7.77 1.04999L7.72 1.03999C7.19 0.889994 6.63 1.16999 6.43 1.67999L4 7.99999H16L13.56 1.67999L13.53 1.62999Z" />
    </SvgIcon>
  );
}
type Params = {
  servico_slug: string;
};

export default function GetServiceEvaluation(): JSX.Element {
  const { servico_slug }: Params = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const history = useHistory();
  const token: string | null = getCookie('gov_access_token_sso');
  const [method, setMethod] = useState<any>('identificada');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [service, setService] = useState<any>();

  const linksBreadcrumb = [
    {
      name: "Meus Agendamentos",
      redirectTo: "/workspace/meus_agendamentos"
    },
    {
      name: "Avaliação de atendimento presencial",
      redirectTo: "#",
    }
  ];

  const handleChangeProfile = (event) => {
    setMethod(event.target.value);
  }

  const handleGoToEvaluation = () => {
    if (method && servico_slug) {
      if (method === "identificada" && !token) {
        setOpenModal(true);
      } else {
        history.push(`/workspace/avaliar_servico/${servico_slug}/${method}`);
      }
    }
  };

  const getServiceSlug = async (slug: string) => {
    try {
      setLoading(true);
      const { data } = await getService(slug);
      setService(data.results[0]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (servico_slug) {
      getServiceSlug(servico_slug);
    }
  }, [servico_slug]);


  return (
    <>
      {token && <Breadcrumb links={linksBreadcrumb} />}
      <Container>

        <Box className={classes.boxTitle}>
          <Typography className={classes.title}>Avaliação de atendimento presencial</Typography>
        </Box>
        <Box className={classes.boxEvaluation}>
          <Box className={classes.evaluation}>
            {loading && <Box className={classes.boxSuccess}>
              <CircularProgress />
            </Box>}
            {service && (<>
              <Box className={classes.texts}>
                <Typography className={classes.title}>Selecione o seu perfil</Typography>
                <Typography className={classes.textInfoRating}>Qual forma você deseja avaliar o serviço</Typography>
              </Box>
              <Box className={classes.boxSelectProfile}>
                <Box className={classes.boxLabelRadio} onClick={() => setMethod("identificada")}>
                  <Box className={classes.boxIconProfile}><AccountCheckIcon color="primary" style={{
                    marginRight: 10
                  }}

                  /> Identificada </Box>
                  <Radio
                    checked={method === 'identificada'}
                    color="primary"
                    value="identificada"
                    onChange={handleChangeProfile}
                  />
                </Box>
                <Box className={classes.boxLabelRadio} onClick={() => setMethod("anonima")}>
                  <Box className={classes.boxIconProfile}><IncognitoIcon color="primary" style={{
                    marginRight: 10
                  }}

                  /> Anônima </Box>
                  <Radio
                    checked={method === 'anonima'}
                    color="primary"
                    value="anonima"
                    onChange={handleChangeProfile}
                  />
                </Box>
              </Box>
              <Box className={classes.boxActions}>
                <Button
                  variant='contained'
                  size='small'
                  className={classes.buttonReturn}
                  onClick={() => { history.push('/workspace/buscar_servico/avaliar') }}
                >Voltar</Button>
                <Button className={classes.buttonNext}
                  variant='contained'
                  color='primary'
                  size='small'
                  disabled={!method}
                  onClick={handleGoToEvaluation}
                >
                  Selecionar
                </Button>
              </Box>
            </>)}
          </Box>
        </Box>
      </Container>
      <ModalNotAuth open={openModal} onClose={() => setOpenModal(false)} />
    </>
  )
}