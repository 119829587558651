import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format, isValid, subDays } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import { useEffect, useState } from "react";
import {
  MdCheckCircle,
  MdClose,
  MdDeleteForever,
  MdEvent,
  MdMouse,
} from "react-icons/md";
import { useHistory } from "react-router-dom";
import {
  Schedule,
  ScheduleUser
} from "../../../../interfaces/agendamentos";
import { SearchI } from "../../../../interfaces/servico";
import { UserData } from "../../../../interfaces/user";
import {
  anexarArquivo,
  atenderOnline,
  checarDisponibilidadeAgendamentos,
  checarHorarios,
  checarIntervaloAgendamentos,
  incluirDependente,
  salvarMotivosTopicos,
} from "../../../../services/atendimentos";
import { getService } from "../../../../services/servico";
import {
  cpfMask,
  removeCpfMask,
  validateCpf,
} from "../../../../utils/cpfFormat";
import dateFormat from "../../../../utils/dateFormat";
import getCookie from "../../../../utils/getCookies";

interface SavedDependenteProps {
  agendamento: number;
  cpf: string;
  cpf_solicitante: string;
  data_nascimento: string;
  grau_parentesco: string;
  id: number;
  nome: string;
}

interface SavedScheduleProps {
  cpf: string;
  data: string;
  data_solicitacao: string;
  guiche: number;
  hora: string;
  hora_inicio: string;
  id: number;
  para_dependente: boolean;
  prioridade: boolean;
  servico: number;
  setor: string;
  status: string;
  ticket: string;
  tipo: string;
  unidade: string;
  updated_at: string;
  user: number;
}

interface Props {
  open: boolean;
  handleClose: () => void;
  type: "Chat" | "Video";
  serviceSelected: SearchI;
  setShowResult: (set: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: "#d3d3d3",
      },
    },
    "& .MuiSelect-iconOutlined": {
      height: 24,
      width: 24,
    },
  },
  rootDialog: {
    '& .MuiDialogContent-root': {
      overflowY: 'hidden',
    },
  },
  header: {
    background: theme.palette.primary.main,
  },
  textHeader: {
    display: "flex",
    alignItems: "center",
    textTransform: "uppercase",
    width: "90%",
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
    width: "100%",
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: "16px",
    fontStyle: "Molish",
    color: "#373F41",
    marginTop: 10,
    fontWeight: 700,
    lineHeight: "20px",
  },
  buttonClose: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: "#fff",
  },
  textSchedule: {
    color: "#373F41",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(20),
    marginTop: 30,
  },
  textSubtitleSchedule: {
    color: "#373F41",
    fontWeight: 400,
    fontSize: theme.typography.pxToRem(16),
    marginTop: 20,
  },
  iconUpload: {
    color: "#9E9E9E",
  },
  rootUpload: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 140,
    flexDirection: "column",
  },
  adornedEnd: {
    paddingRight: 0,
  },
  inputSearch: {
    maxWidth: 582,
    width: "100%",
    height: 48,
    border: `1px solid ${theme.palette.primary.main}`,
    outline: "none",
    paddingLeft: 16,
    fontFamily: "Mulish",
    fontSize: 14,
    borderRadius: 0,
  },
  optionsList: {
    marginTop: "16px",
    width: "100%",
    border: "0.5px solid #DADCE0",
    maxWidth: "540px",
    height: "48px",
    borderLeftWidth: "2px",
    borderLeftColor: `${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  optionsListText: {
    fontSize: "14px",
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Mulish",
  },
  boxnNextOptions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
  },
  textarea: {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    marginBottom: '10px',
    fontSize: '16px',
  },
  cardUploads: {
    width: '100%',
    border: `1px solid #E1E6EF`,
    boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    color: "#6B6D6E",
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    marginBottom: 2,
    marginTop: 5,
  },
  serviceSelectedTextTitle: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    fontSize: "14px",
  },
  serviceSelectedText: {
    color: "#373F41",
    fontWeight: 600,
    fontFamily: "Mulish",
    fontSize: "16px",
    marginTop: "16px",
  },
  serviceSelectedSubText: {
    color: "#373F41",
    fontFamily: "Mulish",
    fontWeight: 300,
    fontSize: "16px",
  },
  buttonAddArchives: {
    backgroundColor: `${theme.palette.primary.main}`,
    height: "40px",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "#0A5D9C",
    },
  },
  boxConfirmScheduling: {
    display: "flex",
    justifyContent: "center",
    padding: "20px",
  },
  buttonConfirmScheduling: {
    backgroundColor: "rgba(60, 210, 120, 0.7)",
    borderRadius: 0,
    width: "300px",
    "&:hover": {
      backgroundColor: "#3CD278",
    },
  },
  multilineColor: {
    color: "#000",
  },
  inputCheckbox: {
    color: "#333333",
    fontSize: 16,
    fontWeight: 500,
    fontFamily: "Roboto",
  },
  inputCheckboxText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontstyle: "normal",
    fontWeight: 400,
    textAlign: "left",
    color: "rgba(55, 63, 65, 0.6)",
  },
  colorInputText: {
    color: theme.palette.primary.main,
  },
  text: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: 16,
    color: "rgba(55, 63, 65, 0.6)",
  },
  infoText: {
    fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 400,
    color: "rgba(55, 63, 65, 0.6)",
    margin: "8px 0px",
  },
  box: {
    borderRadius: 4,
    border: "1px solid #E1E6EF",
    margin: "20px 32px",
  },
  titleBox: {
    fontSize: theme.typography.pxToRem(14),
    color: "#333333",
    fontWeight: 500,
    textTransform: "uppercase",
    fontFamily: "Roboto",
    margin: "10px 0px 30px 0px",
  },
  boxInfoConcluded: {
    margin: "20px 32px",
    borderRadius: 4,
    padding: "16px 24px",
    border: "1px solid #0BB07B",
    backgroundColor: "rgba(11, 176, 123, 0.05)",
  },
  infoConcluded: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textInfoUnity: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#706E7A",
    marginTop: 16,
    marginBottom: 8,
  },
  textInfoDateTime: {
    fontFamily: "Roboto",
    fontSize: 16,
    fontWeight: 400,
    color: "#706E7A",
    marginBottom: 4,
  },
  buttonPrimary: {
    margin: "10px 0px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.primary,
    width: 186,
    height: 44,
    "&:hover": {
      backgroundColor: "rgba(15, 111, 183, 0.7)",
    },
  },
  textButtonPrimary: {
    fontFamily: "Roboto",
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
    textTransform: "uppercase",
  },
  buttonOutlined: {
    width: 186,
    height: 44,
    padding: "14px 16px",
    marginLeft: "0px !important",
  },
  textButtonOutlined: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.primary.main,
    fontFamily: "Roboto",
    fontWeight: 700,
    textTransform: "uppercase",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textSubtitleScheduleBox: {
    width: 165,
    height: 30,
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(225, 230, 239, 0.3)",
    color: "#333333",
  },
  textError: {
    color: theme.palette.error.dark,
    fontSize: 12,
    fontWeight: 700,
    marginLeft: 10,
    fontFamily: "Roboto",
  },
  icon: {
    height: 24,
    width: 24,
  },
  iconLarge: {
    height: 80,
    width: 80,
  },
  errorTextStyle: {
      color: '#ff4d4d',
      marginTop: '5px',
      fontSize: 12,
      fontWeight: 400,
  },
  errorUploadCard: {
    width: '100%',
    border: `1px solid #F03D3D`,
    boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
    backgroundColor: '#fff',
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    marginBottom: 2,
    marginTop: 5,
  },
  formControlList: {
    width: '100%',
  },
}));

interface UnidatyDate {
  setor_unidade: number;
  unidade_slug: string;
  unidade_nome: string;
  datas_disponiveis: string[];
}

export default function ModalShedulingPresential({
  open,
  handleClose,
  type,
  serviceSelected: service,
  setShowResult,
}: Props): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [unitySelected, setUnitySelected] = useState<string>('');
  const [checkedDependente, setCheckedDependente] = useState(false);
  const [serviceSelected, setServiceSelected] = useState<SearchI | undefined>();
  const [cpfDependente, setCpfDependente] = useState<string>('');
  const [errorCpfDependente, setErrorCpfDependente] = useState<undefined | string>(undefined);
  const [nameDependente, setNameDependente] = useState<string>('');
  const [errorNameDependente, setErrorNameDependente] = useState<undefined | string>(undefined);
  const [birthDateDependente, setBirthDateDependente] = useState<Date>(subDays(new Date(), 30));
  const [dependenteSelected, setDependenteSelected] = useState<string>('');
  const [scheduleServiceUser, setScheduleServiceUser] = useState<ScheduleUser | string | undefined>();
  const [selectedDate, handleDateChange] = useState<Date | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<FileObject[]>([]);
  const [openUploads, setOpenUploads] = useState<boolean>(false);
  const [scheduleService, setScheduleService] = useState<string | Schedule | undefined>();
  const [scheduleSelected, setScheduleSelected] = useState<string>('');
  const [loadingDepente, setLoadingDepedente] = useState(false);
  const [savedSchedule, setSavedSchedule] = useState<SavedScheduleProps>(undefined);
  const [savedDependente, setSavedDependente] = useState<SavedDependenteProps>(undefined);
  const [loadingFinally, setLoadingFinally] = useState(false);
  const [hiddenInfoShedule, setHiddenInfoSchedule] = useState(true);
  const [showBoxData, setShowBoxData] = useState(false);
  const [isErrorInUpload, setIsErrorInUpload] = useState(false);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [unidades, setUnidades] = useState<UnidatyDate[]>([]);
  const [formData, setFormData] = useState({
    descricaoProblema: '',
    motivoAtendimento: '',
  });
  const [isAttachmentRequired, setIsAttachmentRequired] = useState(false);
  const handleTextareaChange = (e) => {
    setFormData({ ...formData, descricaoProblema: e.target.value });
  };

  const handleSelectChange = (e) => {
    setFormData({ ...formData, motivoAtendimento: e.target.value });
  };

  const handleCheckDependente = () => {
    setCheckedDependente(!checkedDependente);
  };

  const schedulerService = async (): Promise<void> => {
    setLoading(true);
    const token: string | null = getCookie('gov_access_token_sso');
    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (token && userData) {
      try {
        const { data, status } = await atenderOnline(
          token,
          service.id,
          unitySelected,
          service.titulo,
          `Agendamento para ${service.titulo}`,
          service.setor_id,
          'Video',
          selectedDate,
          scheduleSelected,
          checkedDependente,
        );
        setScheduleServiceUser(data);

        if (status === 201) {
          setSavedSchedule(data);
        }

        setLoading(false);
      } catch (err) {
        if (err.response?.data?.message) {
          setScheduleServiceUser(err.response?.data?.message);
        } else if (err.response?.data?.[0]) {
          setScheduleService(err.response?.data?.[0]);
        } else {
          setScheduleService('Algo inesperado ocorreu.');
        }
        setLoading(false);
      }
    }
  };

  const handleDependente = async () => {
    const token: string | null = getCookie('gov_access_token_sso');
    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    const cpfDependenteWithoutMask = removeCpfMask(cpfDependente);

    if (nameDependente && nameDependente.length < 6) {
      setErrorNameDependente('Nome deve ter no mínimo 6 caracteres.');
      return;
    }

    if (cpfDependenteWithoutMask) {
      if (!validateCpf(cpfDependenteWithoutMask) || cpfDependenteWithoutMask.length < 11) {
        setErrorCpfDependente('CPF inválido.');
      }
    }

    if (savedSchedule.para_dependente) {
      try {
        setLoadingDepedente(true);
        const { data, status } = await incluirDependente(
          savedSchedule.id,
          cpfDependenteWithoutMask,
          nameDependente,
          birthDateDependente,
          userData.cpf,
          dependenteSelected,
          token,
        );

        if (status === 201) {
          setSavedDependente(data);
        }
        setLoadingDepedente(false);
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingDepedente(false);
      }
    }
  };

  const getStartAndEndOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();

    const data_inicio = new Date(year, month, 1).toISOString().split('T')[0];
    const data_fim = new Date(year, month + 1, 0).toISOString().split('T')[0];

    return { data_inicio, data_fim };
  };

  const handleCheckInterval = async (startDate, endDate, unitySelected, serviceId) => {
    const token = getCookie('gov_access_token_sso');
    if (!token) {
      console.error('Token not found');
      return;
    }

    const { data_inicio, data_fim } = startDate && endDate ? { data_inicio: startDate, data_fim: endDate } : getStartAndEndOfMonth(new Date());

    try {
      const response = await checarIntervaloAgendamentos(
        token,
        serviceId.toString(),
        'Online',
        unitySelected,
        data_inicio,
        data_fim,
      );
      setAvailableDates(response?.data);
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleMonthChange = async (date, unitySelected, serviceId) => {
    const { data_inicio, data_fim } = getStartAndEndOfMonth(date);
    await handleCheckInterval(data_inicio, data_fim, unitySelected, serviceId);
  };

  const handleListScheduleDates = async (serviceId) => {
    const token = getCookie('gov_access_token_sso');
    if (!token) {
      console.error('Token not found');
      return;
    }

    try {
      const response = await checarDisponibilidadeAgendamentos(token, serviceId.toString(), 'Online');
      setUnidades(response.data);
    } catch (error) {
      console.error('Error fetching available dates:', error);
    }
  };

  useEffect(() => {
    setAvailableDates([])
    handleDateChange(null)
    setScheduleService(undefined)
    setScheduleServiceUser(undefined)

    if (unitySelected) {
      const { data_inicio, data_fim } = getStartAndEndOfMonth(new Date());
      handleCheckInterval(data_inicio, data_fim, unitySelected, service.id);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitySelected]);

  const isDateAvailable = (date: Date) => {
    const formattedDate = date.toISOString().split('T')[0];
    return availableDates?.includes(formattedDate);
  };

  const handleSubmitMotivos = async () => {
    const token: string | null = getCookie('gov_access_token_sso');
    try {
      await salvarMotivosTopicos(
        token,
        savedSchedule.id.toString(),
        formData.descricaoProblema,
        formData.motivoAtendimento,
      );
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleUploads = () => {
    if (serviceSelected.configuracao_agendamento?.anexo && files.length === 0) {
      setIsAttachmentRequired(true);
      return;
    }

    const token: string | null = getCookie('gov_access_token_sso');

    if (savedSchedule && files.length >= 1) {
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const fileUpload of files) {
          anexarArquivo(
            token,
            fileUpload.file.name,
            fileUpload.file.type,
            fileUpload.file,
            savedSchedule.id.toString(),
          );
        }
        handleSubmitMotivos();
      } catch (error) {
        console.log(error);
      }
    }

    setShowBoxData(true);
    setHiddenInfoSchedule(false);
  };

  const handleUploadsAlert = (variant: string) => {
    if (variant === 'error') {
      setIsErrorInUpload(true);
    } else {
      setIsErrorInUpload(false);
    }
  };

  const handleFinally = () => {
    setShowBoxData(true);
    setHiddenInfoSchedule(true);
    window.location.replace('/workspace/meus_agendamentos');
  };

  const removeFile = (file: FileObject) => {
    const fileFilter = files.filter((fileFiltering) => fileFiltering.data !== file.data);
    setFiles(fileFilter);
  };

  const getSchedulers = async (date: Date, unity: string): Promise<void> => {
    setScheduleService(undefined);
    setScheduleServiceUser(undefined);
    if (isValid(date)) {
      try {
        const { data } = await checarHorarios(date, unity, service.id);
        setScheduleService(data);
      } catch (err) {
        if (err.response) {
          setScheduleService(err.response.data.message);
        } else if (err.response?.data?.[0]) {
          setScheduleService(err.response?.data?.[0]);
        } else {
          setScheduleService('Algo inesperado ocorreu.');
        }
      }
    } else {
      setScheduleService(undefined);
    }
  };

  const optionsDependentes = [
    {
      id: 0,
      option: 'Filho',
    },
    {
      id: 1,
      option: 'Filha',
    },
    {
      id: 2,
      option: 'Enteado',
    },
    {
      id: 3,
      option: 'Enteada',
    },
    {
      id: 4,
      option: 'Pai',
    },
    {
      id: 5,
      option: 'Mãe',
    },
    {
      id: 6,
      option: 'Avó',
    },
    {
      id: 7,
      option: 'Avô',
    },
    {
      id: 8,
      option: 'Tutor',
    },
    {
      id: 9,
      option: 'Tutora',
    },
  ];

  useEffect(() => {
    async function handleGetService() {
      try {
        setLoading(true);
        const { data } = await getService(service.slug);
        setServiceSelected(data?.results[0]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    if (service) {
      handleGetService();
    }
    setUnitySelected('');
    setScheduleServiceUser(undefined);
    setScheduleService(undefined);
    setFiles([]);
    setOpenUploads(false);
    handleListScheduleDates(service?.id.toString());
  }, [open]);

  const handleDialogClose = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    handleClose();
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ root: classes.rootDialog }}
    >
      {(!savedSchedule || !serviceSelected?.configuracao_agendamento?.anexo) && (
        <IconButton
          onClick={handleClose}
          className={classes.buttonClose}
        >
          <MdClose style={{ width: 24, height: 24 }} />
        </IconButton>
      )}
      <div hidden={showBoxData}>
        <DialogTitle
          className={classes.header}
        >
          <Typography className={classes.textHeader}>
            <MdMouse style={{
              marginRight: 15, width: 24, height: 24,
            }}
            />
            Atendimento online
          </Typography>
        </DialogTitle>
        {loading ? (
          <Box style={{ marginTop: 20, textAlign: 'center' }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <>
            {!savedSchedule ? (
              <Box className={classes.box}>
                <DialogContent>
                  <Typography className={classes.titleBox}>Faça seu agendamento</Typography>
                  <div id="input-check">
                    <FormControlLabel
                      className={classes.inputCheckbox}
                      control={(
                        <Checkbox
                          checked={checkedDependente}
                          onChange={handleCheckDependente}
                          name="dependente"
                        />
                      )}
                      label="Dependente"
                    />
                    <Typography className={classes.inputCheckboxText}>Selecione caso este agendamento seja para seu dependente.</Typography>
                  </div>
                  <FormControl
                    classes={{ root: classes.root }}
                    error={typeof (scheduleServiceUser) === 'string'}
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">Selecione a unidade</InputLabel>
                    <Select
                      error={typeof (scheduleServiceUser) === 'string'}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      // value={age}
                      onChange={(event): void => {
                        setScheduleServiceUser(undefined);
                        setUnitySelected(event.target.value as string);
                        getSchedulers(selectedDate, event.target.value as string);
                      }}
                      label="Selecione a unidade"
                      classes={{
                        select: classes.menuItem,
                      }}
                      MenuProps={{
                        MenuListProps: {
                          disablePadding: true,
                        },
                      }}
                    >
                      {unidades?.map((unity) => (
                        <MenuItem
                          key={unity.setor_unidade}
                          className={classes.menuItem}
                          value={unity.unidade_slug}
                        >
                          {unity.unidade_nome}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <div id="input-data">
                    <FormControl
                      classes={{ root: classes.root }}
                      error={typeof (scheduleService) === 'string' || scheduleService === undefined || typeof (scheduleServiceUser) === 'string'}
                      disabled={!unitySelected}
                      variant="outlined"
                      className={classes.formControl}
                      key={String(selectedDate)}
                    >
                      {/* <StylesProvider generateClassName={generateClassName}> */}
                      {/* <ThemeProvider theme={materialTheme}> */}
                      <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                          disableFuture={false}
                          error={((typeof scheduleService === 'string' || typeof scheduleServiceUser === 'string') && availableDates.length !== 0)}
                          minDateMessage="Você não pode agendar em dias anteriores"
                          invalidDateMessage="A data informada é inválida"
                          minDate={new Date()}
                          InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                          autoOk
                          disabled={!unitySelected || !availableDates || availableDates.length === 0}
                          variant="inline"
                          inputVariant="outlined"
                          label="Selecione o dia"
                          format="dd/MM/yyyy"
                          placeholder="00/00/0000"
                          value={selectedDate || null}
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={(date) => {
                            if (date && isValid(date)) {
                              handleDateChange(date);
                              if (unitySelected) {
                                getSchedulers(date, unitySelected);
                              }
                            }
                          }}
                          shouldDisableDate={(date) => !isDateAvailable(date)}
                          onMonthChange={(date) => handleMonthChange(date, unitySelected, service.id)}
                        />
                      </MuiPickersUtilsProvider>
                      {/* </ThemeProvider> */}
                      {/* </StylesProvider> */}
                      {typeof (scheduleService) === 'string' && availableDates.length !== 0 && (
                        <FormHelperText>{scheduleService}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <div id="input-horario">
                    <FormControl
                      classes={{ root: classes.root }}
                      disabled={!unitySelected || !selectedDate || typeof scheduleService === 'string' || typeof scheduleServiceUser === 'string' || (scheduleService && Object.keys(scheduleService).length === 0)}
                      variant="outlined"
                      className={classes.formControl}
                      error={typeof (scheduleServiceUser) === 'string'}
                    >
                      <InputLabel id="demo-simple-select-filled-label">Escolha o horário</InputLabel>
                      <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        classes={{
                          select: classes.menuItem,
                        }}
                        // value={age}
                        onChange={(event): void => {
                          setScheduleSelected(event.target.value as string);
                        }}
                        label="Escolha o horário"
                        MenuProps={{
                          MenuListProps: {
                            disablePadding: true,
                          },
                        }}
                      >
                        {typeof (scheduleService) !== 'string' && scheduleService?.horarios?.map((schedule) => (
                          <MenuItem key={schedule} className={classes.menuItem} value={schedule}>
                            {schedule}
                          </MenuItem>
                        ))}
                      </Select>
                      {typeof (scheduleServiceUser) === 'string' && (
                        <FormHelperText>{scheduleServiceUser}</FormHelperText>
                      )}
                    </FormControl>
                  </div>
                  <Box style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  >
                    <Button
                      style={{
                        textTransform: 'uppercase',
                        borderRadius: 4,
                        marginTop: 20,
                        marginBottom: 20,
                      }}
                      disabled={
                        !unitySelected
                        || !selectedDate
                        || !scheduleSelected
                        || typeof (scheduleService) === 'string'
                        || typeof (scheduleService) === 'undefined'
                      }
                      // color="primary"
                      id="salvar-agendamento"
                      variant="contained"
                      onClick={schedulerService}
                    >
                      Salvar
                    </Button>
                  </Box>
                </DialogContent>
              </Box>
            ) : (
              <Box className={classes.boxInfoConcluded}>
                <Box className={classes.infoConcluded}>
                  <Typography className={classes.titleBox} style={{ margin: 0 }}>Agendamento Salvo com sucesso</Typography>
                  <MdCheckCircle style={{ color: '#0BB07B', width: 24, height: 24 }} />
                </Box>
                <Box>
                  <Typography className={classes.textInfoDateTime}>
                    {dateFormat(savedSchedule?.data, false)}
                    {' '}
                    -
                    {' '}
                    {savedSchedule?.hora}
                  </Typography>
                </Box>
              </Box>
            )}
          </>
        )}

        {savedSchedule?.para_dependente && (
          <>
            {loadingDepente ? (
              <Box style={{ marginTop: 30, textAlign: 'center' }}>
                <CircularProgress color="primary" />
              </Box>
            ) : (
              <>
                {!savedDependente ? (
                  <Box className={classes.box}>
                    <DialogContent>
                      <Typography className={classes.titleBox}>dados do dependente</Typography>
                      <div className="input-name">
                        <FormControl
                          variant="outlined"
                          fullWidth
                          error={typeof (errorNameDependente) === 'string'}
                          className={classes.formControl}
                          classes={{ root: classes.root }}
                        >
                          <InputLabel htmlFor="outlined-name-dependente">
                            Nome Completo
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-name-dependente"
                            type="text"
                            label="Nome Completo"
                            autoFocus
                            className={classes.colorInputText}
                            value={nameDependente}
                            onChange={(event) => {
                              setNameDependente(event.target.value);
                              setErrorNameDependente(undefined);
                            }}
                            labelWidth={70}
                            inputProps={{ minLength: 6, maxLength: 100 }}
                          />
                          {typeof (errorNameDependente) === 'string' && (
                            <FormHelperText>{errorNameDependente}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div id="input-cpf">
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className={classes.formControl}
                          classes={{ root: classes.root }}
                          disabled={!nameDependente}
                          error={typeof (errorCpfDependente) === 'string'}
                        >
                          <InputLabel htmlFor="outlined-name-dependente">
                            CPF
                          </InputLabel>
                          <OutlinedInput
                            id="outlined-name-dependente"
                            type="text"
                            label="cpf"
                            autoFocus
                            className={classes.colorInputText}
                            value={cpfDependente}
                            onChange={(event) => {
                              setCpfDependente(cpfMask(event.target.value));
                              setErrorCpfDependente(undefined);
                            }}
                            labelWidth={70}
                            inputProps={{ maxLength: 14, minLength: 14 }}
                          />
                          {typeof (errorCpfDependente) === 'string' && (
                            <FormHelperText>{errorCpfDependente}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div id="input-date">
                        <FormControl
                          classes={{ root: classes.root }}
                          error={typeof (scheduleService) === 'string' || scheduleService === undefined || typeof (scheduleServiceUser) === 'string'}
                          disabled={!unitySelected}
                          variant="outlined"
                          className={classes.formControl}
                          key={String(selectedDate)}
                        >
                          {/* <StylesProvider generateClassName={generateClassName}> */}
                          {/* <ThemeProvider theme={materialTheme}> */}
                          <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                              disableFuture={false}
                              error={((typeof scheduleService === 'string' || typeof scheduleServiceUser === 'string') && availableDates.length !== 0)}
                              minDateMessage="Você não pode agendar em dias anteriores"
                              invalidDateMessage="A data informada é inválida"
                              minDate={new Date()}
                              InputProps={{ className: classes.menuItem, classes: { adornedEnd: classes.adornedEnd } }}
                              autoOk
                              disabled={!unitySelected || !availableDates || availableDates.length === 0}
                              variant="inline"
                              inputVariant="outlined"
                              InputAdornmentProps={{ position: 'end', color: "#004F9F" }}
                              label="Selecione o dia"
                              format="dd/MM/yyyy"
                              placeholder="00/00/0000"
                              value={selectedDate || null}
                              onChange={(date) => {
                                if (date && isValid(date)) {
                                  handleDateChange(date);
                                  if (unitySelected) {
                                    getSchedulers(date, unitySelected);
                                  }
                                }
                              }}
                              shouldDisableDate={(date) => !isDateAvailable(date)}
                              onMonthChange={(date) => handleMonthChange(date, unitySelected, service.id)}
                            />
                          </MuiPickersUtilsProvider>
                          {/* </ThemeProvider> */}
                          {/* </StylesProvider> */}
                          {typeof (scheduleService) === 'string' && availableDates.length !== 0 && (
                            <FormHelperText>{scheduleService}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div id="input-parentesco">
                        <FormControl
                          classes={{ root: classes.root }}
                          variant="outlined"
                          className={classes.formControl}
                          disabled={!birthDateDependente || typeof (scheduleService) === 'string'}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Grau de parentesco
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            onChange={(event): void => {
                              setDependenteSelected(event.target.value as string);
                            }}
                            value={dependenteSelected || ''}
                            label="Grau de parentesco"
                            classes={{
                              select: classes.menuItem,
                            }}
                            MenuProps={{
                              MenuListProps: {
                                disablePadding: true,
                              },
                            }}
                          >
                            {optionsDependentes?.map((unity) => (
                              <MenuItem
                                key={unity.id}
                                className={classes.menuItem}
                                value={unity.option}
                              >
                                {unity.option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <Box style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                      >
                        <Button
                          onClick={handleDependente}
                          style={{
                            textTransform: 'uppercase',
                            borderRadius: 4,
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                          disabled={
                            !nameDependente || !cpfDependente || !birthDateDependente || !dependenteSelected
                          }
                          // color="primary"
                          id="salvar-dependente"
                          variant="contained"
                        >
                          Salvar
                        </Button>
                      </Box>
                    </DialogContent>
                  </Box>
                ) : (
                  <Box className={classes.boxInfoConcluded}>
                    <Box className={classes.infoConcluded}>
                      <Typography className={classes.titleBox} style={{ margin: 0 }}>Informações salva com sucesso</Typography>
                      <MdCheckCircle style={{ color: '#0BB07B', width: 24, height: 24 }} />
                    </Box>
                    <Box>
                      <Typography className={classes.textInfoUnity}>{savedDependente.nome}</Typography>
                      <Typography className={classes.textInfoDateTime}>{savedDependente.cpf}</Typography>
                      <Typography className={classes.textInfoDateTime}>{dateFormat(savedDependente.data_nascimento, false)}</Typography>
                      <Typography className={classes.textInfoDateTime}>{savedDependente.grau_parentesco}</Typography>
                    </Box>
                  </Box>
                )}
              </>
            )}
          </>
        )}

        {savedSchedule && (
          <Box
            className={classes.box}
            style={{
              border: 'none',
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            {(serviceSelected?.configuracao_agendamento?.anexo || serviceSelected.configuracao_agendamento?.descricao_anexo)
              && (
                <>
                  <Box style={{ padding: '0px 0px 0px 0px' }}>
                      <Typography className={classes.titleBox} style={{ margin: 0 }}>
                        {serviceSelected.configuracao_agendamento?.descricao_anexo}
                     </Typography>
                  </Box>
                  <div>
                    <Button
                      disabled={!unitySelected || typeof (scheduleServiceUser) === 'string'}
                      onClick={(): void => setOpenUploads(true)}
                      style={{
                        borderRadius: 4,
                        color: '#004F9F',
                        border: '1px solid #004F9F',
                        display: openUploads ? 'none' : 'block',
                        textTransform: 'uppercase',
                      }}
                    >
                      Adicionar arquivo
                    </Button>

                    <div
                      hidden={!openUploads}
                      style={{
                        color: '#9E9E9E',
                        marginTop: 5,
                        marginLeft: 10,
                        width: '97%',
                      }}
                    >
                      <DropzoneAreaBase
                        onAdd={(filesUpload) => {
                          setFiles(filesUpload);
                          if (filesUpload.length > 0) setIsAttachmentRequired(false);
                        }}
                        fileObjects={files}
                        acceptedFiles={['image/jpeg', 'image/png', '.pdf']}
                        showPreviewsInDropzone={false}
                        dropzoneText="Faça upload ou solte arquivos aqui"
                        showFileNames={false}
                        showFileNamesInPreview={false}
                        dropzoneParagraphClass={classes.text}
                        showAlerts={false}
                        filesLimit={10}
                        maxFileSize={31457280}
                        classes={{
                          icon: classes.iconUpload,
                          root: classes.rootUpload,
                        }}
                        onAlert={(_, variant) => handleUploadsAlert(variant)}
                      />
                      <Typography className={classes.infoText}>
                        Tamanho máximo: 30MB
                      </Typography>
                      {files?.map((file) => (
                        <Card className={classes.cardUploads} key={file.file.name}>
                          {file.file.name}
                          <IconButton onClick={() => removeFile(file)}>
                            <MdDeleteForever style={{ color: 'red', width: 24, height: 24 }} />
                          </IconButton>
                        </Card>
                      ))}
                      {isErrorInUpload && (
                        <Card className={classes.errorUploadCard}>
                          <Typography className={classes.errorTextStyle}>
                            Arquivo inválido, ou excede o limite de tamanho.
                          </Typography>
                          <IconButton onClick={() => setIsErrorInUpload(false)}>
                            <MdDeleteForever
                              style={{
                                width: 24,
                                height: 24,
                              }}
                            />
                          </IconButton>
                        </Card>
                      )}
                    </div>
                    {isAttachmentRequired && (
                      <Typography className={classes.errorTextStyle}>
                        É obrigatório adicionar um anexo.
                      </Typography>
                    )}
                  </div>
                </>
              )}

            {serviceSelected.configuracao_agendamento?.descricao_problema && (
              <TextareaAutosize
                placeholder={serviceSelected.configuracao_agendamento?.descricao_problema}
                rows={4}
                className={classes.textarea}
                value={formData.descricaoProblema}
                onChange={handleTextareaChange}
              />
            )}

            {serviceSelected.configuracao_agendamento?.motivos?.length !== 0 && 
            serviceSelected.configuracao_agendamento?.motivos?.some((motivo) => motivo.ativo) && (
              <FormControl
                variant="outlined"
                fullWidth
                className={classes.formControlList}
              >
                <InputLabel id="demo-simple-select-outlined-label">Motivo do atendimento</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={formData.motivoAtendimento}
                  onChange={handleSelectChange}
                  label="Motivo do atendimento"
                  classes={{
                    select: classes.menuItem,
                  }}
                >
                  <MenuItem value="">
                    <em>Nenhum</em>
                  </MenuItem>
                  {serviceSelected.configuracao_agendamento?.motivos?.filter((motivo) => motivo.ativo)?.map((motivo) => (
                    <MenuItem
                      key={motivo.id}
                      className={classes.menuItem}
                      value={motivo.id}
                    >
                      {motivo.titulo}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}

        {savedSchedule && (
          <DialogActions
            style={{
              display: 'flex',
              margin: '0px 32px',
            }}
          >
            <Box style={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            >
              <Button
                onClick={handleUploads}
                style={{
                  textTransform: 'uppercase',
                  borderRadius: 4,
                  marginBottom: 20,
                }}
                variant="contained"
                color="primary"
                disabled={
                  !unitySelected || !savedSchedule || checkedDependente
                    ? !checkedDependente
                    || !nameDependente
                    || !cpfDependente
                    || !birthDateDependente
                    || !dependenteSelected
                    || !savedDependente
                    : false
                }
                id="salvar-dependente"
              >
                Finalizar
              </Button>
            </Box>
          </DialogActions>
        )}
      </div>
      <div
        hidden={hiddenInfoShedule}
      >
        <DialogContent style={{ textAlign: 'center' }}>
          <Box>
            <MdCheckCircle style={{
              color: '#3CD278', width: 80, height: 80, fontSize: 24,
            }}
            />
          </Box>
          <Typography className={classes.textSchedule}>
            Agendamento concluído, enviamos para você um comprovante,
            verifique no seu email.
          </Typography>
          <Typography className={classes.textSubtitleSchedule}>
            Não esqueça do seu horário marcado, você receberá uma notificação antes do seu atendimento iniciar.
          </Typography>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {savedSchedule && (
              <Typography
                className={classes.textSubtitleScheduleBox}
                style={{ fontWeight: 600, marginTop: 30 }}
              >
                {(typeof selectedDate?.getMonth === 'function') && format(selectedDate, 'dd/MM/yyyy')}
                {' '}
                às
                {' '}
                {scheduleSelected}
              </Typography>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            margin: '10px 0px',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {loadingFinally ? (
            <Box style={{ marginTop: 30, textAlign: 'center' }}>
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <Button
              className={classes.buttonPrimary}
              onClick={handleFinally}
            >
              <Typography className={classes.textButtonPrimary}>Meus agendamentos</Typography>
            </Button>
          )}
          <Button
            onClick={() => {
              handleClose();
              history.push('/');
            }}
            className={classes.buttonOutlined}
          >
            <Typography className={classes.textButtonOutlined}>Voltar para o início</Typography>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
}
