import React from 'react';
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, makeStyles, useTheme } from '@material-ui/core';
import { FavoriteSystemRequest } from '../..';
import { IoIosArrowForward } from 'react-icons/io';

const useStyles = makeStyles((theme: Theme) => ({
  tableHeadCell: {
    color: '#212121',
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '19.6px',
    backgroundColor: "#fff",
  },
  tableHeader: {
    padding: '16px'
  },
  tableRow: {
    borderBottom: '1px solid #004F9F80',
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  },
  icon: {
    color: theme.palette.primary.main,
  },
  actionAccess: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '12px',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: "#fff"
  },
  notFound: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  tableContainer: {
    maxHeight: '670px',
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const ListSystem = ({ list, loading, setNewAccess }: { list: FavoriteSystemRequest[], loading, setNewAccess: (perfil: any) => void }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={classes.tableHeader} >
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableHeadCell}>Sigla do Sistema</TableCell>
            <TableCell className={classes.tableHeadCell}>Nome do Sistema</TableCell>
            <TableCell className={classes.tableHeadCell}>Estrutura</TableCell>
            <TableCell className={classes.tableHeadCell}>Perfil</TableCell>
            <TableCell className={classes.tableHeadCell}>Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableCell colSpan={5} style={{ borderBottom: '2px solid #004F9F80', padding: 0 }} />
        <TableBody>
          {loading ? (
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableHeadCell} component="th" scope="row"></TableCell>
              <TableCell className={classes.tableHeadCell} ></TableCell>
              <TableCell className={classes.tableHeadCell} > <CircularProgress size={50} style={{ color: theme.palette.primary.main }} /></TableCell>
            </TableRow>
          ) : (list && list.length > 0 ? (
            list?.map((favorite) => {
              return (
                <>
                  <TableRow className={classes.tableRow}>
                    <TableCell className={classes.tableHeadCell} component="th" scope="row" style={{ minWidth: '150px'}}>{favorite.sigla}</TableCell>
                    <TableCell className={classes.tableHeadCell} >{favorite.nome}</TableCell>
                    <TableCell className={classes.tableHeadCell} >{favorite.extra_data.geoEstruturaNome}</TableCell>
                    <TableCell className={classes.tableHeadCell} >{favorite.extra_data.nomeGrupo}</TableCell>
                    <TableCell className={classes.tableHeadCell} >
                      <button onClick={() => setNewAccess(favorite)} className={classes.actionAccess}>
                        Acessar
                        <IoIosArrowForward size={20} className={classes.icon} />
                      </button>
                    </TableCell>
                  </TableRow>
                </>
              );
            })) : (
              <TableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center', color: '#000' }}>
                    <p>Não há Sistemas</p>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ListSystem;
