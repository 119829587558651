import { Paper } from "@material-ui/core";
import  { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MyEditor from "../../Components/MyEditor";
import { getDocumentosResposta } from "../../services/form-flow";
import dateFormat from "../../utils/dateFormat";
import getCookie from "../../utils/getCookies";


export default function DocRequestView(): JSX.Element {
    const { uuid_doc, identificador_solicitacao
    } = useParams<{
        uuid_doc: string; identificador_solicitacao: string;
    }>()
    const [dataDoc, setDataDoc] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getDoc = async () => {
            setLoading(true)
            let user = JSON.parse(getCookie('gov_user_data') || '{}');
            try {
                if (uuid_doc && identificador_solicitacao && user.cpf) {
                    const { data } = await getDocumentosResposta(uuid_doc, {
                        identificador_solicitacao: identificador_solicitacao || '',
                        cpf: user.cpf || '',
                    });
                    setDataDoc(data);
                    console.log(data);
                }
            } catch (error) {

            } finally {
                setLoading(false);
            }
        };
        getDoc();

    }, [uuid_doc, identificador_solicitacao]);
    
    const getQRCodeImageURL = (value) => `https://api.qrserver.com/v1/create-qr-code/?data=${encodeURIComponent(value)}&size=80x80`;

    const addInfoSign = () => {
        const qrCodeValue = `${atob(process.env.REACT_APP_URL_CONSULTAR_DOC)}/${dataDoc?.hash}/${dataDoc?.id}`;
        const qrCodeImgURL = getQRCodeImageURL(qrCodeValue);

        return `
        <br/>

         <div style="text-align: left; background: #F3F4F7; position: fixed; bottom: 0; width: 100%; margin-top: 10px; font-size: 11px; display: flex;  align-items: center;">
          <div>
         ${dataDoc?.assinado_sistema ? `Autenticidade gerada automaticamente pelo sistema` : `Assinado eletronicamente por: ${dataDoc?.user?.username || ``} • ${dataDoc?.user?.first_name || ``} `}
            em ${dataDoc?.updated_at && dateFormat(dataDoc?.updated_at, true)}
            <br/>
            Hash de verificação:  ${dataDoc?.hash}
            <br/>
            Identificação:  ${dataDoc?.id}
            <br/>
            “Essa assinatura tem respaldo legal de acordo com a lei federal n° 14.129”<br />
            Consulte a autenticidade do documento em: ${atob(process.env.REACT_APP_URL_CONSULTAR_DOC)}
            </div>
             <img src="${qrCodeImgURL}" alt="QRCode" style="image-rendering: crisp-edges; -ms-interpolation-mode: nearest-neighbor; margin-left: 10px" />
        </div>`;
    }

    return (<Paper style={{
        color: '#000',
    }}>
        {loading && !dataDoc ? <div>Carregando...</div> : <>
            <MyEditor
                value={
                    `${dataDoc?.cabecalho?.conteudo}
                    ${dataDoc?.conteudo}
                    ${addInfoSign()}

                    `}
                print={true}
                addConf={{
                    height: 800,
                }}
            />

        </>}
    </Paper>);

}