/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Card, Typography, makeStyles, Theme, useMediaQuery, FormControlLabel, Collapse, Paper, useTheme } from "@material-ui/core";
import { getAtividades } from "../../../../services/servico";
import { Atividade } from "../../../../interfaces/atividades";
import TabFavorite from "./TabFavorite";
import { actionType } from "../../store/reducer";
import { useStateValue } from "../../store/stateProvider";
import getCookie from "../../../../utils/getCookies";
import RecommendationServices from "../RecommendationServices";
import { IoIosArrowDown } from "react-icons/io";
import { FaRegBookmark } from "react-icons/fa";
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  boxActions: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '14px 14px 0px 14px',
    borderRadius: 4,
  },
  textTitleSelected: {
    cursor: 'pointer',
    padding: '15px 40px',
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
  },
  textTitle: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    padding: '15px 40px',
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 500,
  },
  boxActivy: {
    width: '100%',
    textAlign: 'center'
  },
  boxFavorite: {
    width: '100%',
    textAlign: 'center'
  },
  icon: {
    width: '32px',
    height: '32px',
    color: theme.palette.primary.main,
  },
  title: {
    margin: 0,
    color: '#212121',
    fontWeight: 700,
    fontSize: '18px'
  },
}));


function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabsActionsFavoriteProps {
  serviceSelected: string;
  setServiceSelected: (type: 'solicitacoes' | 'favoritos') => void
}

export default function TabsActionsFavorite({ serviceSelected, setServiceSelected }: TabsActionsFavoriteProps): JSX.Element {
  const classes = useStyles();
  const [atividades, setAtividades] = useState<Array<Atividade> | undefined>();
  const [{ favorites }, dispatch] = useStateValue();
  const token: string | null = getCookie('gov_access_token_sso');
  const theme = useTheme();

  const matchesMobile = useMediaQuery("(min-width:600px) and (max-width:1000px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const getAtividadesAll = async () => {
    if (token) {
      try {
        const { data } = await getAtividades(token);

        setAtividades(data.atividades);
        dispatch({
          type: actionType.GET_FAVORITES,
          favorites: data.favoritos
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    getAtividadesAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    if (!matchesMobile) {
      setServiceSelected('favoritos')
    }
  }, [matchesMobile]);

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      <Card style={{ backgroundColor: checked && theme.palette.primary.main }}>

        {(!matchesMobile && !isMobile ) && <Box display="flex" margin="16px" style={{ gap: '8px', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => checked && handleChange()}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <FaRegBookmark fontSize={24} size={24} color={theme.palette.primary.main} />
            <h3 className={classes.title} style={{ marginLeft: '16px', fontWeight: 700 }}>Favoritos</h3>
          </div>
          <IoIosArrowDown />
        </Box>}

        {isMobile &&
          <Box display="flex" margin="16px" style={{ gap: '8px', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }} onClick={() => handleChange()}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <FaRegBookmark fontSize={24} size={24} color={checked ? '#fff' : theme.palette.primary.main} />
              <h3 style={{ color: checked ? '#fff' : "#212121", marginLeft: '16px', fontSize: '16px' }} >Favoritos</h3>
            </div>
            <IoIosArrowDown fontSize={24} size={24} color={checked ? '#fff' : theme.palette.primary.main} />
          </Box>
        }

        <Box className={classes.boxActions} style={{ border: !isMobile && `1px solid ${theme.palette.primary.main}`, }}>
          {matchesMobile &&
            <Box
              className={classes.boxActivy}
              onClick={(): void => setServiceSelected('solicitacoes')}
            >
              <Typography
                className={
                  serviceSelected === 'solicitacoes'
                    ? classes.textTitleSelected
                    : classes.textTitle
                }
              >
                Minhas Solicitações
              </Typography>
            </Box>
          }
          {matchesMobile && (
            <Box
              className={classes.boxFavorite}
              onClick={() => { setServiceSelected('favoritos') }}
            >
              <Typography
                className={
                  serviceSelected === 'favoritos'
                    ? classes.textTitleSelected
                    : classes.textTitle
                }
              >
                Favoritos
              </Typography>
            </Box>
          )}
        </Box>
        {(serviceSelected === 'solicitacoes' && matchesMobile) && (
          <TabPanel value={0} index={0}>
            <RecommendationServices />
          </TabPanel>
        )}
        {isMobile && <Collapse in={checked}>
          <Paper elevation={4}>
            {serviceSelected === 'favoritos' && (
              <TabPanel value={0} index={0}>
                <TabFavorite favorites={favorites} />
              </TabPanel>
            )}
          </Paper>
        </Collapse>}
        {!isMobile &&
          serviceSelected === 'favoritos' && (
            <TabPanel value={0} index={0}>
              <TabFavorite favorites={favorites} />
            </TabPanel>
          )
        }
      </Card>
    </>
  );
}
