import { AxiosResponse } from "axios";
import { apisso } from "./api";

export const getSystems = (token?: string): Promise<AxiosResponse> => apisso.get("/admin/gsi/sistemas/", {
  headers: {
    Authorization: `Bearer ${token}`,
  }
});

export const getSystemsFavorites = (token?: string): Promise<AxiosResponse> => apisso.get("/admin/gsi/sistemas/favoritos/", {
  headers: {
    Authorization: `Bearer ${token}`,
  }
});

interface ExtraData {
  grupoID: number;
  nomeGrupo: string;
  colecaoID: number;
  geoEstruturaID: number;
  geoEstruturaSigla: string;
  geoEstruturaNome: string;
  tipoEnderecoID: number;
  endereco: string;
}

interface FavoriteSystemData {
  system_id: number;
  group_id: number;
  geo_estrutura_id: number;
  sigla: string;
  nome: string;
  descricao: string;
  endereco: string;
  extra_data: ExtraData;
}

export const setSystemsFavorite = (data: FavoriteSystemData, token?: string): Promise<AxiosResponse> => {
  return apisso.post("/admin/gsi/sistemas/favoritar/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

interface RemoveFavoriteSystem {
  system_id: number,
  group_id: number,
  geo_estrutura_id: number
}

export const setSystemsRemoveFavorite = (data: RemoveFavoriteSystem, token?: string): Promise<AxiosResponse> => {
  return apisso.post("/admin/gsi/sistemas/desfavoritar/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};

interface ExtraData {
  grupoID: number;
  nomeGrupo: string;
  colecaoID: number;
  geoEstruturaID: number;
  geoEstruturaSigla: string;
  geoEstruturaNome: string;
  tipoEnderecoID: number;
  endereco: string;
}

interface FavoriteSystemData {
  system_id: number;
  group_id: number;
  geo_estrutura_id: number;
  sigla: string;
  nome: string;
  descricao: string;
  endereco: string;
  extra_data: ExtraData;
}

export const setRegisterAccess = (data: RemoveFavoriteSystem, token?: string): Promise<AxiosResponse> => {
  return apisso.post("/admin/gsi/sistemas/acessos/registrar/", data, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
};


export const getSystemsAccess = (token?: string): Promise<AxiosResponse> => apisso.get("/admin/gsi/sistemas/acessos/", {
  headers: {
    Authorization: `Bearer ${token}`,
  }
});
