import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Box,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useToastValue } from '../../../../contexts/toastMessage';
import { cancelRequest } from '../../../../services/form-flow';
import getCookie from '../../../../utils/getCookies';

export interface SimpleDialogProps {
  open: boolean;
  requestId: number;
  handleClose: () => void;
  onReload: ({ page }) => void;
}

const useStyles = makeStyles(() => ({
  boxHeader: {
    backgroundColor: '#E53535',
  },
  textName: {
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
  },
  textUnidade: {
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '23px',
    textTransform: 'capitalize',
    color: '#737B7D',
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  btnConfirm: {
    backgroundColor: '#3CD278',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',

    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#00a04b',
    },
  },
  btnClose: {
    backgroundColor: '#b0bec5',
    border: 'none',
    color: '#FFFFFF',
    borderRadius: '0',

    padding: '10px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#808e95',
    },
  },
}));

export default function RequestModalCancel({
  open,
  handleClose,
  requestId,
  onReload
}: SimpleDialogProps) {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const { setToast } = useToastValue();

  const onCancelRequest = async (id: number) => {
    const token: string | null = getCookie('gov_access_token_sso');

    setLoading(true);
    if (token) {
      try {
        const { data } = await cancelRequest('Cancelado pelo usuário', id);
        setLoading(false);
        if (data) {
          handleClose();
          setToast({
            visible: true,
            message: 'Cancelamento realizado com sucesso!',
            type: 'success'
          });
          onReload({ page: 1 });
        }
      } catch (err) {
        setLoading(false);
        setToast({
          visible: true,
          message: 'Erro ao realizar o cancelamento.',
          type: 'success'
        });
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle
        id="alert-dialog-title"
        className={classes.boxHeader}

      >
        <Typography className={classes.textName}>Deseja cancelar a solicitação?</Typography>
      </DialogTitle>
      <DialogActions>
        {loading ? (
          <Box
            marginBottom="60px"
            width="100%"
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Button variant="contained" color="primary" className={classes.btnConfirm} onClick={() => onCancelRequest(requestId)}>
              Sim, eu quero cancelar
            </Button>
            <Button onClick={handleClose} variant="contained" color="primary" className={classes.btnClose}>
              Não, eu não quero cancelar
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}