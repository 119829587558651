import { Box, Divider,  makeStyles} from '@material-ui/core';
import { Sistema } from '../..';
import { IoIosArrowForward } from 'react-icons/io';
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '672px',
    maxHeight: '672px',
    width: '390px',
    zIndex: 2,
    borderRadius: '8px',
    left: '100%',
    top: 0,
    overflowY: 'auto',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    borderStartStartRadius: '8px',
    borderStartEndRadius: '8px',
    padding: '11px 8px',
    backgroundColor: '#fff',
  },
  divider: {
    width: '362px',
    height: '2px',
    margin: '11px 0px',
    borderRadius: '8px',
    background: '#004F9F4D',
  },
  headerDescription: {
    fontSize: '14px',
    margin: 0,
    fontWeight: 500,
    color: '#212121'
  },
  detailCard: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 24px',
    backgroundColor: '#FFFFFF',
    borderTop: '1px solid #DDDDDD',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background-color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      '& $icon': {
        color: '#fff',
      },
      '& $iconFavorite': {
        color: '#fff',
        fill: '#fff',
      },
      '& $a': {
        color: '#fff',
      },
      '& $h2': {
        color: '#fff',
      },
    },
  },
  actionAccess: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    fontSize: '12px',
  },
  detailradius: {
    borderEndStartRadius: '8px',
    borderEndEndRadius: '8px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    color: '#212121',
  },
  icon: {
    color: theme.palette.primary.main,
  },
  iconFavorite: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const CardListActionsSctructure = ({ listCards, setSelectedStructure }: { listCards: Sistema,  setSelectedStructure: (props: any) => void }) => {
  const classes = useStyles();

  const DetailCard = ({ card, isLastItem }) => {
    return (
      <>
        <Box key={card.geoEstruturaID} className={`${classes.detailCard} ${isLastItem ? classes.detailradius : ''}`} onMouseEnter={() => setSelectedStructure(card)} >
          <div className={classes.actionAccess}>
            <h2 className={classes.title}>{card.geoEstruturaNome}</h2>
          </div>
          <button className={classes.actionAccess}
            style={{
              cursor: 'pointer',
              border: 'none',
              backgroundColor: "transparent"
            }}>
              <IoIosArrowForward size={20} className={classes.icon} />
            </button>
        </Box>
      </>
    );
  };

  return (
    <Box className={classes.container} >
      <Box style={{ border: '1px solid #DDDDDD' }}>
        <Box className={classes.header}>
          <p className={classes.headerDescription}>Selecione uma estrututura</p>
          <Divider className={classes.divider} />
        </Box>
        {listCards &&
          listCards.geoEstruturas.map((card, index) => {
            return (
              <>
                <DetailCard
                  key={card.geoEstruturaID}
                  card={card}
                  isLastItem={index === listCards.geoEstruturas[0].perfis.length - 1}
                />
              </>
            )
          })} 
      </Box>
    </Box>
  );
}

export default CardListActionsSctructure;
