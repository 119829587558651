import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Breadcrumbs, Container, Typography } from "@material-ui/core";
import { MdHome } from 'react-icons/md';
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";

interface Props {
  links: Array<{
    name: string;
    redirectTo: string;
  }>;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    height: '56px',
    background: '#fff',
    padding: "0px 10px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 0px",
    }
  },
  breadcrumbs: {
    flexGrow: 1,
  },
  LinkIcon: {
    display: "flex",
    alignItems: "center",
    color: "#595959",
    textDecoration: "none",
  },
  LinkIconWork: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 24,
    height: 24,
  },
  textBreadcrumbs: {
    display: "block",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 14,

    color: theme.palette.primary.main,
    textDecoration: "none",
    textTransform: "capitalize",
  },
}));

export default function Breadcrumb({ links }: Props): JSX.Element {
  const classes = useStyles();
  return (
      <Box className={classes.main}>
      <Container>
        <Box
        display="flex"
        alignItems="center"
        width="100%"
        flexWrap="wrap"
        justifyContent="flex-start"
      >
        <Breadcrumbs separator={<IoIosArrowForward size={16} color={"#212121"} />} className={classes.textBreadcrumbs}>
          <a href="/" className={classes.LinkIcon}>
            <MdHome className={classes.icon} /> portal
          </a>
          <Link to="/workspace" className={classes.LinkIconWork}>
            Meu Painel
          </Link>
          {links.map(({ name, redirectTo }, i) => (
            <Link key={i} to={redirectTo} className={classes.textBreadcrumbs}>
              <Typography className={classes.textBreadcrumbs}>
                {name}
              </Typography>
            </Link>
          ))}
        </Breadcrumbs>
      </Box>
      </Container>
    </Box>
  );
}
