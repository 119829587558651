import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Theme,
  Typography,
  makeStyles
} from "@material-ui/core";
import React, { useState } from "react";

interface Props {
  titulo: string;
  conteudo: string;
  versao: string;
  loading: boolean;
  onSubmit: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: 'Roboto',
    fontWeight: 500,
    color: '#264259',
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: 16,
    padding: 20,
  },
  buttonForm: {
    backgroundColor: '#264259',
    width: '100%',
    maxWidth: 100,
  },
  contentTerm: {
    border: '1px solid #eee',
    overflowY: 'auto',
    borderRadius: 6,
    height: 400,
    marginBottom: 10,
    padding: 15,
  },
  box: {
    padding: 20,
    width: '100%'
  },
  inputCheckbox: {
    color: "#373F41",
  },
  inputCheckboxText: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontstyle: "normal",
    fontWeight: 400,
    textAlign: "left",
    color: "rgba(55, 63, 65, 0.6)",
  },
  contentActionsTerm: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}));

export default function Terms({
  titulo,
  conteudo,
  versao,
  loading,
  onSubmit
}: Props): JSX.Element {
  const classes = useStyles();
  const [checkedTerms, setCheckedTerms] = useState<boolean>(false);

  const handleCheckTerms = () => {
    setCheckedTerms(!checkedTerms);
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center">
      <Typography variant="h6" className={classes.title}>
        {titulo} - {versao}
      </Typography>
      <Box className={classes.box}>
        <Box className={classes.contentTerm} dangerouslySetInnerHTML={{ __html: conteudo }} />
        <Box className={classes.contentActionsTerm}>
          <div id="input-check">
            <FormControlLabel
              className={classes.inputCheckbox}
              control={
                <Checkbox
                  checked={checkedTerms}
                  onChange={handleCheckTerms}
                  name="accept"
                  color="primary"
                />
              }
              label="Li e concordo com os termos"
            />
          </div>
          <Button
            className={classes.buttonForm}
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={(!checkedTerms) || loading}
          >
            AVANÇAR
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}