import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography, IconButton, Grid, Container } from '@material-ui/core';
import { MdArrowBack } from "react-icons/md";
import { Breadcrumb, PaginationService } from '../../Components';
import { CardFavorite } from './components';
import { useEffect } from 'react';
import { getFavoriteService } from '../../services/servico';
import { useState } from 'react';
import { ServicosFavorito } from "../../interfaces/servico";
import getCookie from '../../utils/getCookies';

type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  boxIndicatorsBack: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "20px 0px",
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: "#373F41",
    maxWidth: "1250px",
    alignItems: "center",
  },
  boxTermsList: {
    backgroundColor: "#fff",
    borderRadius: 4,
    marginBottom: 22,
  },
  title: {
    fontSize: 16,
    fontWeight: 700,
    fontFamily: "Roboto",
    textTransform: "uppercase",
    color: "#373F41",
  },
  boxDateInfo: {
    display: "flex",
    color: "#373F41",
    alignItems: "center",
    marginTop: 17,
    flexWrap: "wrap",
  },
  date: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: 16,
    fontStyle: "normal",
    margin: "0px 4px",
    color: "#373F41",
    opacity: 0.6,
  },
}));



export default function Favorite({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [favorites, setFavorites] =
    useState<ServicosFavorito | undefined>();
  const linksBreadcrumb = [
    {
      name: "Serviços favoritos",
      redirectTo: "#",
    },
  ];
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );
  const token: string | null = getCookie('gov_access_token_sso');

  const getAllFavorites = async (page?: number) => {
    if (token) {
      try {
        const { data } = await getFavoriteService(token, page);

        if (data?.results) {
          setFavorites(data);
        }
      } catch (error) {
        console.log(error);
      }

    }

  };

  useEffect(() => {
    getAllFavorites(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton onClick={() => history.push('/workspace')}>
                  <MdArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Serviços favoritos
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {favorites &&
            favorites.results &&
            favorites.results.map((favorite) => (
              <Grid item xs={12} sm={12} md={6}>
                <CardFavorite setFavorites={setFavorites} favorite={favorite} key={favorite.servico.slug} />
              </Grid>
            ))}
        </Grid>
      </Container>
      {favorites && favorites.results.length ? (
        <PaginationService getSearch={getAllFavorites} listSearch={favorites} />
      ) : (
        ""
      )}
    </>
  );
}
