import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  btnCancel: {
    width: 83,
    marginRight: 16,
    borderRadius: 4,
    color: "#373F41",
    border: 0,
    textTransform: "uppercase",
  },
  btnConfirm: {
    backgroundColor: '#264259',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2, 3),
  },
  dialogContent: {
    padding: theme.spacing(2, 3, 0, 3),
  },
  dialogTitle: {
    padding: theme.spacing(2, 3),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  confirmationText: {
    fontWeight: 'bold',
    color: '#333'
  },
}));

function GuidanceModal({
  open,
  handleClose,
  handleConfirm,
  description,
}) {
  const classes = useStyles();

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContent}>
        <DialogContentText
          id="alert-dialog-description"
          style={{ marginBottom: 0 }}
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Typography className={classes.confirmationText}>
          Deseja continuar com o agendamento?
        </Typography>
        <Box>
          <Button
            onClick={handleClose}
            variant="contained"
            className={classes.btnCancel}
          >
            Não
          </Button>
          <Button
            onClick={handleConfirm}
            variant="contained"
            color="primary"
            autoFocus
          >
            Sim
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default GuidanceModal;
