import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { loginRedirectPrivider } from '../../services/auth';

interface Props {
  open: boolean;
  onClose: () => void;
}


export default function ModalNotAuth({
  open,
  onClose,
}: Props): JSX.Element {

  const location = useLocation();

  const loginRedirect = () => {
    const { pathname } = location;
    loginRedirectPrivider(pathname);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle style={{ color: '#000' }}>Você não tem permissão para continuar</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para continuar você precisa estar logado no portal único de serviços. Clique em logar para ser redirecionado para o login único.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button style={{ textTransform: 'none' }} onClick={onClose} color="primary">
          Fechar
        </Button>
        <Button variant="outlined" onClick={loginRedirect} color="primary">
          Fazer login
        </Button>
      </DialogActions>
    </Dialog>
  );
}
