import React, { useEffect, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Grid,
  Container,
  IconButton,
  Tabs,
  Tab,
  useMediaQuery,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";

import { MdArrowBack, MdOutlineHistory } from "react-icons/md";
import { Breadcrumb } from "../../Components";
import { useHistory } from "react-router-dom";
import CardListActions from "./components/card";
import { getSystems, getSystemsAccess, getSystemsFavorites, setRegisterAccess } from "../../services/sistema";
import getCookie from "../../utils/getCookies";
import ListSystem from "./components/listSystem";
import { FaRegBookmark } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowDown } from "react-icons/io";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
    minHeight: '80vh'
  },
  boxIndicatorsBack: {
    width: "100%",
    justifyContent: "center",
    marginTop: '20px'
  },
  indicatorsBack: {
    display: "flex",
    width: "100%",
    color: theme.palette.primary.main,
    fontWeight: 700,
    maxWidth: "1250px",
    alignItems: "center",
  },
  icon: {
    width: 18,
    height: 18,
    color: theme.palette.primary.main,
  },
  boxFavorite: {
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  tab: {
    height: '57px',
    padding: '16px',
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    width: '290px',
    borderBottomColor: 'transparent',
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      borderBottomColor: theme.palette.primary.main,
    },
    '&:not(.Mui-selected)': {
      backgroundColor: 'white',
      color: '#A6A6A6',
      border: `1px solid ${theme.palette.primary.main}80`,
    },
    '& .MuiTab-wrapper': {
      gap: '8px',
      flexDirection: 'row',  
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));


interface ExtraData {
  sistemaID: number;
  sigla: string;
  nomeSistema: string;
  integracaoGSI: string;
  grupoID: number;
  nomeGrupo: string;
  colecaoID: number;
  geoEstruturaID: number;
  geoEstruturaSigla: string;
  geoEstruturaNome: string;
  tipoEnderecoID: number;
  endereco: string;
}

export interface FavoriteSystemRequest {
  system_id: number;
  sigla: string;
  group_id: string;
  nome: string;
  extra_data: ExtraData;
}

type Perfil = {
  grupoID: number;
  nomeGrupo: string;
};

export type GeoEstrutura = {
  geoEstruturaID: number;
  geoEstruturaSigla: string;
  geoEstruturaNome: string;
  tipoEnderecoID: number;
  endereco: string;
  perfis: Perfil[];
};

export type Sistema = {
  sistemaID: number;
  sigla: string;
  nomeSistema: string;
  integracaoGSI: string;
  geoEstruturas: GeoEstrutura[];
};


export default function MySystems(): JSX.Element {
  const [loading, setLoading] = useState(true);
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [systems, setSystems] = useState<Sistema[]>([]);
  const [systemsFavorites, setSystemsFavorites] = useState<FavoriteSystemRequest[]>([]);
  const [systemsAccess, setSystemsAccess] = useState<FavoriteSystemRequest[]>([]);
  const history = useHistory();
  const classes = useStyles();
  const token: string | null = getCookie('gov_access_token_sso');
  const [tab, setTab] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const getFavorites = async () => {
    try {
      const { data: dataFavorites } = await getSystemsFavorites(token);
      setSystemsFavorites(dataFavorites);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    } finally {
      setLoadingFavorites(false)
    }
  }

  const getSystemsAccessRequest = async () => {
    try {
      const { data } = await getSystemsAccess(token);
      setSystemsAccess(data);
    } catch (error) {
      console.error('Error fetching favorites:', error);
    } finally {
      setLoadingFavorites(false)
    }
  }

  const setNewAccess = async (perfil: any) => {
    const favoriteSystemData = {
      system_id: perfil.system_id,
      group_id: perfil.group_id,
      geo_estrutura_id: perfil.extra_data.geoEstruturaID,
      sigla: perfil.sigla,
      nome: perfil.nome,
      descricao: perfil.descricao,
      endereco: perfil.extra_data.endereco,
      extra_data: {
        grupoID: perfil.extra_data.grupoID,
        nomeGrupo: perfil.extra_data.nomeGrupo,
        colecaoID: perfil.extra_data.colecaoID,
        geoEstruturaID: perfil.extra_data.geoEstruturaID,
        geoEstruturaSigla: perfil.extra_data.geoEstruturaSigla,
        geoEstruturaNome: perfil.extra_data.geoEstruturaNome,
        tipoEnderecoID: perfil.extra_data.tipoEnderecoID,
        endereco: perfil.extra_data.endereco
      }
    };

    try {
      await setRegisterAccess(favoriteSystemData, token);
      getSystemsAccessRequest()
      window.open(perfil?.extra_data?.endereco, '_blank'); 
    } catch (error) {
      console.error('Error fetching favorites:', error);
    } finally {
      setLoadingFavorites(false)
    }
  }

  const getSystemsRequest = async () => {
    try {
      const { data } = await getSystems(token);
      setSystems(data);
    } catch (error) {
      console.error('Error fetching systems:', error);
    } finally {
      setLoading(false);
    }
  }

  const linksBreadcrumb = [
    {
      name: "Meus sistemas",
      redirectTo: "#",
    },
  ];

  useEffect(() => {
    if (token) {
      getSystemsRequest();
      getFavorites();
      getSystemsAccessRequest()
    }
  }, [systems.length == 0])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(950));

  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container>
          <Grid item xs={12} >
            <Box className={classes.boxIndicatorsBack} >
              <Box className={classes.indicatorsBack} style={{ display: 'flex', flexDirection: 'row' }}>
                <IconButton
                  onClick={() => history.push("/workspace")}
                >
                  <IoIosArrowBack className={classes.icon} />
                </IconButton>
                Voltar
              </Box>
            </Box>
          </Grid>

          <Box className={classes.boxIndicatorsBack} style={{ display: 'flex', flexDirection: 'column', color: '#212121', margin: '24px 0px 40px 0px' }}>
            <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
              Meus Sistemas
            </Typography>
            <Typography style={{ fontFamily: "Roboto", fontWeight: 400, fontSize: '14px' }}>
              Sistemas de acesso ao servidor.
            </Typography>
          </Box>

          <Grid container item xs={12}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
              <CardListActions getSystemsRequest={getSystemsRequest} listCards={systems} loading={loading} listFavorites={systemsFavorites} getFavorites={getFavorites} />
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
              {isMobile ? (
                // Renderizar Collapse para dispositivos móveis
                <>
                  <Accordion style={{ marginBottom: '32px', border: 'none' }}>
                    <AccordionSummary className={classes.boxFavorite}  >
                        <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
                          <FaRegBookmark
                            fontSize={20}
                            size={20}
                            color="#fff"
                          />
                          <Typography>
                            Sistemas favoritos
                          </Typography>
                        </div>
                        <IoIosArrowDown fontSize={24} size={24} color={'#fff'} />
                    </AccordionSummary >
                    <AccordionDetails style={{ padding: '0px' }}>
                      <ListSystem list={systemsFavorites} loading={loadingFavorites} setNewAccess={setNewAccess} />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary className={classes.boxFavorite}>
                      <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', width: '100%' }}>
                        <MdOutlineHistory
                          fontSize={20}
                          size={20}
                          color="#fff"
                        />
                        <Typography>
                          Últimos acessos
                        </Typography>
                     </div>
                      <IoIosArrowDown fontSize={24} size={24} color={'#fff' } />
                    </AccordionSummary>
                    <AccordionDetails style={{ padding: '0px' }}>
                      <ListSystem list={systemsAccess} loading={loadingFavorites} setNewAccess={setNewAccess} />
                    </AccordionDetails>
                  </Accordion>
                </>
              ) : (
                <>
                  <Tabs
                    value={tab}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <Tab
                       icon={
                         <FaRegBookmark
                           fontSize={20}
                           size={20}
                         />
                       }
                        style={{ display: 'flex', flexDirection: 'row' }}
                      label="Sistemas favoritos"
                      className={classes.tab}
                    />
                    <Tab
                      icon={
                        <MdOutlineHistory
                          fontSize={22}
                          size={22}
                        />
                      }
                      label={"Últimos acessos"}
                      className={classes.tab}
                    />
                  </Tabs>
                  <Box>
                    {tab === 0 && <ListSystem list={systemsFavorites} loading={loadingFavorites} setNewAccess={setNewAccess} />}
                    {tab === 1 && <ListSystem list={systemsAccess} loading={loadingFavorites} setNewAccess={setNewAccess} />}
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
