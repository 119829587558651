import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  Link,
  MenuItem,
  Popover,
  useMediaQuery,
} from "@material-ui/core";
import { Container } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import {
  MdExpandMore,
  MdBusiness,
  MdFace,
  MdBusinessCenter,
} from "react-icons/md";

import { useHistory } from "react-router-dom";
import { UserData } from "../../interfaces/user";
import { setCookie } from "../../utils/setCookie";
import getCookie from "../../utils/getCookies";
import ModalProfile from "../ModalProfile";
import Breadcrumb from "../Breadcrumb";
import SelectProfile from "../SelectProfile";

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFFFFF",
  },
  navContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: '0px'
  },
}));

export default function NavMenu(): JSX.Element {
  const classes = useStyles();
  const [openModalProfile, setOpenModalProfile] = React.useState(false)
  const currentProfile = getCookie("perfil");
  const matchesMobile = useMediaQuery("(max-width:660px)");

  const onChangeProfile = () => {
    setOpenModalProfile(!openModalProfile)
  }
  
  React.useEffect(() => {
    if (currentProfile === "Cidadão"){
      onChangeProfile()
    }
  }, [])

  return (
    <Box className={classes.main}>
      <Container className={classes.navContent}>
        <Breadcrumb links={[]} />
        {!matchesMobile && <SelectProfile />}
      </Container>
    </Box>
  );
}
