import React from 'react';
import {
  FormControl,
  FormLabel,
  Radio,
  FormControlLabel,
  RadioGroup,
} from '@material-ui/core';
import {TextFieldProps} from '@material-ui/core/TextField';
import {makeStyles, Theme} from '@material-ui/core/styles';
// import InputMask from 'react-input-mask';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    color: '##333333',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

interface TextSelectProps{
  itens: any[];
}

const MultCheckboxe: React.FC<TextFieldProps & TextSelectProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl component="fieldset">
        <FormLabel component="legend" className={classes.formLabel}>
          {props.label}
        </FormLabel>
        <RadioGroup aria-label={props.id} name={props.id}>
          {props.itens.map((a, i) =>
            <FormControlLabel
              key={i}
              checked={props.value === `${a.valor || ' '}`}
              value={`${a.valor || ' '}`}
              control={<Radio color="primary" onChange={(e) => props.onChange(e)} />}
              label={a.titulo}
            />
          )}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default MultCheckboxe;
