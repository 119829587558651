import React from "react";
import { Box } from "@material-ui/core";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Pagination, PaginationItem } from "@material-ui/lab";
import { CSSProperties } from "@material-ui/styles";
import { RespostasII } from "../../../../interfaces/form-flow";

interface Props {
  getSearch: ({ page }: { page?: number }) => void;
  listSearch?: RespostasII;
  style?: CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 62,
    marginBottom: 100,
    borderRadius: "50%",
    paddingTop: 20,
    paddingBottom: 20,
    color: "#fff",
    "& .Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
    },
  },
  paginationItem: {
    color: "#9E9E9E",
  },
  paginationSelected: {
    color: "#fff",
  },
  paginationIcon: {
    width: 20,
    height: 20,
  },
}));

export default function PaginationRequest({
  getSearch,
  listSearch,
  style,
}: Props): JSX.Element {
  
  const classes = useStyles();
  return (
    <Box className={classes.main} style={style}>
      <Pagination
        renderItem={(item) => (
          <PaginationItem
            {...item}
            classes={{
              root: classes.paginationItem,
              selected: classes.paginationSelected,
              icon: classes.paginationIcon,
            }}
          />
        )}
        count={listSearch.total_pages}
        page={listSearch.current}
        onChange={(e, page) => getSearch({ page })}
      />
    </Box>
  );
}
