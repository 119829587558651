import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { addDays, isAfter } from 'date-fns';
import { useEffect, useState } from 'react';
import { MdSchedule, MdStar, MdToday } from "react-icons/md";
import { useHistory } from 'react-router';
import { PaginationService } from '../../Components';
import { HistoryI, ScheduleHist } from '../../interfaces/agendamentos';
import { IParamsHistory, historico } from '../../services/agendamentoInterno';
import dateFormatToBR from '../../utils/dateFormat';
import getCookie from '../../utils/getCookies';
import { CancelDialog, FilterAttendence } from './components';
import HistoryDialog from './components/HistoryDialog';
import PrintDialog from './components/PrintDialog';

const useStyles = makeStyles((theme: Theme) => ({
  boxIndicatorsBack: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  indicatorsBack: {
    display: 'flex',
    width: '100%',
    color: '#373F41',
    maxWidth: '1250px',
    alignItems: 'center',
  },
  boxTermsList: {
    backgroundColor: '#fff',
    padding: 30,
    borderRadius: 4,
    margin: '20px 0px',
  },
  boxInformationTitle: {
    display: 'flex',
    width: '100%',
    maxWidth: '1105px',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: 'Roboto',
    textTransform: 'uppercase',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  subTitle: {
    color: '#373F41',
    fontFamily: 'Roboto',
    fontSize: 20,
    fontWeight: 500,
    margin: '16px 0px 12px 0px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  description: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    color: '#373F41',
    textTransform: 'capitalize',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  status: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: 500,
    textTransform: 'capitalize',
    padding: '5px 16px',
    borderRadius: 25,
    [theme.breakpoints.down('sm')]: {
      width: 'fit-content',
      marginBottom: 24,
      alignSelf: 'center',
    },
  },
  boxInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#373F41',
    margin: '46px 0px 17px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'Center',
    },
  },
  textInfo: {
    display: 'flex',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: 16,
    fontStyle: 'normal',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 26,
  },
  icon: {
    color: theme.palette.primary.main,
    marginRight: 7,
    width: 20,
    height: 20,
  },
  star: {
    color: theme.palette.primary.main
  },
  infoNumberGuiche: {
    fontFamily: 'Roboto',
    fontWeight: 700,
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  infoTextGuiche: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 16,
    textTransform: 'uppercase',
  },
  textButtonGuiche: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
    width: 131,
    height: 48,
    borderRadius: 4,
    padding: '12px 24px',
  },
  actionsButtonText: {
    textTransform: 'uppercase',
    fontFamily: 'Roboto',
  },
  buttonCancel: {
    marginTop: 10,
    color: '#E53535',
    borderColor: 'rgba(229,53,53, 0.7)',
    borderRadius: 4,
    padding: '12px 24px',
    '&:hover': {
      backgroundColor: 'rgba(229,53,53, 0.1)',
      borderColor: '#E53535',
    },
  },
  notificationCounter: {
    position: 'absolute',
    top: -10,
    right: -10,
    padding: '3px 9px',
    borderRadius: '50%',
    backgroundColor: '#F5365C',
    color: theme.palette.text.primary,
    fontSize: 13,
  },
}));

export default function SchedulingInternal(): JSX.Element {
  const classes = useStyles();
  const history = useHistory();
  const [scheduleHistory, setScheduleHistory] = useState<ScheduleHist>();
  const [scheduleSelected, setScheduleSelected] = useState<HistoryI>();
  const [filterParams, setFilterParams] = useState<IParamsHistory | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);

  const handlePrintDialog = (schedule: HistoryI) => {
    setOpenDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCancelDialog = (schedule: HistoryI) => {
    setOpenCancelDialog(true);
    setScheduleSelected(schedule);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setScheduleSelected(undefined);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setScheduleSelected(undefined);
  };

  const handleHistoryDialog = (schedule: HistoryI) => {
    setOpenHistoryDialog(true);
    setScheduleSelected(schedule);
  }

  const handleCloseHistoryDialog = () => {
    setOpenHistoryDialog(false);
    setScheduleSelected(undefined);
  }

  const handleToEvaluetion = (ticket: string) => {
    history.push(`/workspace/avaliar_atendimento_interno/${ticket}`);
  }

  const handleEvaluationDate = (schedule: HistoryI) => {
    if (!schedule?.avaliacao_interno) {
      return;
    }
    const date = new Date(schedule.avaliacao_interno.created_at);
    const now = new Date();
    const afterOneWeek = addDays(date, 7);
    if (isAfter(afterOneWeek, now) && schedule.avaliacao_interno.pendente) {
      return (
        <Button
          className={classes.textButtonGuiche}
          color='primary'
          variant='outlined'
          onClick={() => handleToEvaluetion(schedule.avaliacao_interno.id)}
          style={{ marginBottom: 10 }}
        >
          <Typography className={classes.actionsButtonText}>
            Avaliar
          </Typography>
        </Button>
      )
    }
    return;
  }

  const getHistory = async (
    page: number,
    params?: IParamsHistory
  ): Promise<void> => {
    (window as any).scrollTo(0, 0);
    setScheduleHistory(undefined);
    const token: string | null = getCookie('gov_access_token_sso');
    setLoading(true);
    if (token) {
      try {
        const { data } = await historico(token, page, params);
        setScheduleHistory(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (filterParams) {
      getHistory(1, filterParams);
    } else {
      getHistory(1);
    }
  }, [filterParams]);

  return (
    <>
      <Grid container>
        <FilterAttendence setFilter={setFilterParams} />
      </Grid>

      {loading ? (
        <Box
          marginBottom='60px'
          width='100%'
          display='flex'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {scheduleHistory && scheduleHistory.results.length > 0 ? (
            scheduleHistory.results.map((schedule) => (
              <>
                <Container>
                  <Grid
                    key={schedule.id}
                    container
                    alignItems='center'
                    justify='space-between'
                    md={12}
                    className={classes.boxTermsList}
                  >
                    <Box className={classes.boxInformationTitle}>
                      {/* <Typography className={classes.title}>
                        {schedule.unidade.nome} (Guichê {schedule.guiche.ordem})
                      </Typography> */}
                      {schedule.status === 'Cancelado' && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: '#F44336',
                            border: '1px solid rgba(248, 50, 69, 0.08)',
                            background: 'rgba(248, 50, 69, 0.08)',
                          }}
                        >
                          Cancelado
                        </Typography>
                      )}
                      {schedule.status === 'Finalizado' && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: '#43A047',
                            border: '1px solid rgba(67, 160, 71, 0.08)',
                            background: 'rgba(67, 160, 71, 0.08)',
                          }}
                          onClick={() => handleHistoryDialog(schedule)}
                        >
                          Finalizado
                        </Typography>
                      )}
                      {schedule.status === 'Aguardando' && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: '#0F6FB7',
                            border: '1px solid rgba(15, 111, 183, 0.08)',
                            background: 'rgba(15, 111, 183, 0.08)',
                          }}
                        >
                          Aguardando
                        </Typography>
                      )}
                      {schedule.status === 'Não Compareceu' && (
                        <Typography
                          className={classes.status}
                          style={{
                            color: '#851d15',
                            border: '1px solid rgba(141, 18, 30, 0.08)',
                            background: 'rgba(141, 18, 30, 0.08)',
                          }}
                        >
                          Não Compareceu
                        </Typography>
                      )}
                    </Box>
                    <Grid item sm={10} xs={12}>
                      <Typography className={classes.subTitle}>
                        {schedule.servico.titulo}
                      </Typography>
                      {schedule?.protocolo && (
                        <Typography className={classes.description}>
                          {`Protocolo de Agendamento: ${schedule.protocolo}`}
                        </Typography>
                      )}
                      {schedule?.protocolo_atendimento && (
                        <Typography className={classes.description}>
                          {`Protocolo de Atendimento: ${schedule.protocolo_atendimento}`}
                        </Typography>
                      )}
                      <Box display='flex' className={classes.boxInfo}>
                        <Typography className={classes.textInfo}>
                          <MdToday
                            className={classes.icon}
                          />
                          {` ${dateFormatToBR(schedule.data)}`}
                        </Typography>
                        <Typography className={classes.textInfo}>
                          <MdSchedule
                            color='primary'
                            fontSize='small'
                            className={classes.icon}
                          />
                          {` ${schedule.hora.slice(0, 5)}`}
                        </Typography>
                        {schedule?.avaliacao_interno?.pendente === false && (
                          <Typography className={classes.textInfo}>
                            <MdStar className={classes.star} style={{ marginRight: 5, width: 20, height: 20 }} />
                            {`${schedule.avaliacao_interno?.avaliacao_consumo} estrelas`}
                          </Typography>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Box
                        marginBottom='10px'
                        display='flex'
                        alignItems='center'
                        flexDirection='column'
                      ></Box>
                      <Box
                        display='flex'
                        alignItems='center'
                        flexDirection='column'
                      >
                        {handleEvaluationDate(schedule)}
                        {schedule.status === 'Finalizado' && (
                          <Button
                            className={classes.textButtonGuiche}
                            color='primary'
                            variant='outlined'
                            onClick={() => handleHistoryDialog(schedule)}
                          >
                            <Typography className={classes.actionsButtonText}>
                              Ver histórico
                            </Typography>
                          </Button>
                        )}
                        {schedule.status === 'Aguardando' ? (
                          <>
                            <Button
                              className={classes.textButtonGuiche}
                              color='primary'
                              variant='outlined'
                              onClick={() => handlePrintDialog(schedule)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                Imprimir
                              </Typography>
                            </Button>
                            <Button
                              className={classes.buttonCancel}
                              variant='outlined'
                              color='inherit'
                              onClick={() => handleCancelDialog(schedule)}
                            >
                              <Typography className={classes.actionsButtonText}>
                                cancelar
                              </Typography>
                            </Button>
                          </>
                        ) : (
                          <>
                            {schedule.status !== 'Cancelado' && (
                              <Button
                                className={classes.textButtonGuiche}
                                color='primary'
                                variant='outlined'
                                onClick={() => handlePrintDialog(schedule)}
                              >
                                <Typography
                                  className={classes.actionsButtonText}
                                >
                                  Imprimir
                                </Typography>
                              </Button>
                            )}
                          </>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Container>
              </>
            ))
          ) : (
            <Box
              marginBottom='60px'
              minHeight='100px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
            >
              <Typography style={{ textAlign: 'center' }} variant='h4'>
                Nenhum resultado foi encontrado.
              </Typography>
            </Box>
          )}
            {scheduleSelected && (
              <HistoryDialog
                open={openHistoryDialog}
                schedule={scheduleSelected}
                handleClose={handleCloseHistoryDialog}
              />
            )}
          {scheduleSelected && (
            <PrintDialog
              open={openDialog}
              type='complementar'
              schedule={scheduleSelected}
              handleClose={handleCloseDialog}
            />
          )}
          {scheduleSelected && (
            <CancelDialog
              open={openCancelDialog}
              schedule={scheduleSelected}
              handleClose={handleCloseCancelDialog}
            />
          )}
        </>
      )}

      {scheduleHistory && scheduleHistory.results.length ? (
        <PaginationService
          getSearch={getHistory}
          listSearch={scheduleHistory}
        />
      ) : (
        ''
      )}
    </>
  );
}
