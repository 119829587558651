import React, { useEffect, useState } from "react";
import NavMenu from "../../Components/NavMenu";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box, Grid, Container, Typography, useMediaQuery } from "@material-ui/core";
import TabsActionsFavorite from "./components/TabsActionsFavorite";
import { UserData } from "../../interfaces/user";
import RecommendationServices from "./components/RecommendationServices";
import { getUserData as getUserDataRequest } from "../../services/auth";
import { StateProvider } from "./store/stateProvider";
import { initialState, reducer } from "./store/reducer";
import { setCookie } from "../../utils/setCookie";
import getCookie from "../../utils/getCookies";
import { firstName } from "../../utils/nameFormat";
import CardListActions from "./components/CardListActions";
import { Link } from "react-router-dom";
import ListBusiness from "./components/listBusiness";
import SelectProfile from "../../Components/SelectProfile";


type Props = {
  history: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  content: {
    display: "flex",
  },
  contentUser: {
    display: 'flex',

    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textName: {
    textTransform: "capitalize",
    paddingTop: 10,
    display: 'flex',
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(28),
    fontWeight: 500,
    margin: '20px 0px',
    color: '#373F41',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(22),
    }
  },
  iconDash: {
    background: theme.palette.primary.main,
    color: '#FFFFFF',
    width: 40,
    height: 40,
    padding: 5,
    borderRadius: 4,
    marginRight: 10,
  },
  linkToAll: {
    fontFamily: "Roboto",
    fontWeight: 700,
    fontSize: 16,
    textDecoration: "none",
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    padding: '10px 0px',
    marginTop: '24px',
    width: '147px',
    textAlign: 'center',
    borderRadius: '2px'
  },
}));

export default function Home({ history }: Props): JSX.Element {
  const classes = useStyles();
  const [userData, setUserData] = useState<UserData | null>();
  const userDataLocal: string | null = getCookie("gov_user_data");
  const [
    serviceSelected,
    setServiceSelected
  ] = useState<'solicitacoes' | 'favoritos'>('favoritos');
  const token: string | null = getCookie("gov_access_token_sso");
  const tokenSiseci: string | null = getCookie(
    "gov_access_token_sso"
  );
  const currentProfile = getCookie("perfil");
  const matchesMobile = useMediaQuery("(min-width:600px) and (max-width:1000px)");
  const isMobile = useMediaQuery("(max-width:600px)");

  const getUserData = async (tokenSso: string, tokenSiseciReceive: string) => {
    try {
      const data = await getUserDataRequest(tokenSso, tokenSiseciReceive);
      setUserData(data);


      setCookie("gov_user_data", JSON.stringify(data));
    } catch (err) {
      console.log(err);

    }
  };

  useEffect(() => {
    if (userDataLocal) {
      setUserData(JSON.parse(userDataLocal));
    } else {
      if (token && tokenSiseci) {
        getUserData(token, tokenSiseci);
      }
    }
  }, [userDataLocal, token, tokenSiseci]);

  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <NavMenu />
      <Container>
        <Box className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.contentUser}>
                <div>
                  <Typography className={classes.textName}>
                    Olá,{" "}
                    {userData?.nome && (
                      <>
                        {`${firstName(userData?.nome?.toLowerCase())}!`}
                      </>
                    )}
                  </Typography>
                </div>
                <div>
                  {isMobile && <SelectProfile />}
                </div>
              </div>
              <CardListActions />
            </Grid>
            {!matchesMobile && <Grid item xs={12} sm={12} md={8} lg={8} xl={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <RecommendationServices />
              <Link to="/workspace/minhas_solicitacoes" className={classes.linkToAll}>
                Todas Solicitações
              </Link>
            </Grid>}
            <Grid item xs={12} sm={12} md={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} alignItems="center">
                  <TabsActionsFavorite serviceSelected={serviceSelected} setServiceSelected={setServiceSelected} />
                </Grid>
              </Grid>
              {serviceSelected == 'favoritos' ?
                <Link to="/workspace/favoritos" className={classes.linkToAll}>
                  Todos Favoritos
                </Link>
                : (
                  <Link to="/workspace/minhas_solicitacoes" className={classes.linkToAll}>
                    Todas Solicitações
                  </Link>
                )}
            </Grid>
          </Grid>
        </Box>
        {/* {currentProfile === "Empresa" &&  <ListBusiness/>} */}
      </Container>
    </StateProvider>
  );
}
