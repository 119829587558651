import React, { useCallback, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { sendFile } from '../../../../services/form-flow';
import { MdDelete, MdOutlineUploadFile } from 'react-icons/md';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#264259',
      },
      display: 'block',
    },
  },
  input: {
    fontSize: '16px',
    padding: '2px',
    width: '100%',
    maxWidth: '150px',
  },
  label: {
    display: 'block',
    marginBottom: theme.spacing(1),
    marginTop: 10,
  },
  boxFile: {

    display: 'flex',
    border: '1px solid #e0e0e0',
    justifyContent: 'space-between',
    padding: '10px',
    borderRadius: '10px',

  },
  button: {
    border: 'none',
    background: 'transparent',
    color: '#264259',
  },
  boxButton: {
    borderLeft: '1px solid #e0e0e0',
    paddingLeft: '10px',
  },
  documentIcon: {
    width: 24,
    height: 24,
    marginRight: 8,
  },
  document: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: `1px solid #E1E6EF`,
    padding: 0,
    marginTop: 7,
    borderRadius: 4,
    width: '100%'
  },
  documentName: {
    fontSize: theme.typography.pxToRem(16),
    fontStyle: 'normal',
    fontWeight: 400,
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    padding: '10px 0px'
  },
  removeDoc: {
    border: 'none',
    backgroundColor: 'transparent',
    width: 50,
    cursor: 'pointer',
  },
  removeIcon: {
    width: 20,
    height: 20,
    color: '#f00',
  },
  main: {
    width: '100%',
    height: '100%',
    minHeight: 96,
    border: '1px solid #E1E6EF',
    borderRadius: 4,
    padding: 16,
  },
  containerInfoActions: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerInfo: {
    width: '100%',
  },
  containerActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    gap: 16,
  },
  containerFileList: {
    width: '100%'
  },
  legendText: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: '#55608099',
    display: 'block',
    marginBottom: 4,
  },
  buttonShow: {
    width: 150,
    height: 32,
    borderRadius: 4,
    border: '1px solid #55608029',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#556080',
    cursor: 'pointer',
    backgroundColor: '#55608029',
  },
  buttonShowDisabled: {
    width: 150,
    height: 32,
    borderRadius: 4,
    border: '1px solid #55608029',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#556080',
    backgroundColor: '#1f222b29',
  },
  boxButtonFile: {
    width: 150,
    height: 32,
    borderRadius: 4,
    border: '1px solid #55608029',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#556080',
    backgroundColor: '#55608029',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  boxButtonFileDisabled: {
    backgroundColor: '#1f222b29',
    width: 150,
    height: 32,
    borderRadius: 4,
    border: '1px solid #55608029',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 400,
    color: '#556080',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtonFile: {
    position: 'relative',
    overflow: 'hidden',
    display: 'inline-block',
    '& input[type=file]': {
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 0
    }
  },
  container: {
    marginBottom: theme.spacing(1),
  }
}));

interface CustomPropsFile extends File {
  id: string;
  url: string;
  nome: string;
}

type Props = {
  required?: boolean,
  id: string,
  label: string,
  value: '' | {
    url: string;
    nome: string;
    id: string;
  }[],
  error?: boolean,
  onChange: (value: '' | {
    url: string;
    nome: string;
    id: string;
  }[]) => void,
  helperText?: string,
  disabled?: boolean,
  numberFilesPermitted?: number,
}

export default function MultArquivo(props: Props): JSX.Element {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [file, setFile] = React.useState<CustomPropsFile[] | null>();
  const [isShowFile, setIsShowFile] = React.useState(false);


  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      let list = [];
      setLoading(true);
      const listFiles: any = event.target.files;
      if (listFiles?.length > 0 && listFiles.length <= props?.numberFilesPermitted) {
        for (const fileUpload of listFiles) {
          const { data } = await sendFile(fileUpload, false);

          const dataSend = {
            url: data.arquivo,
            nome: fileUpload.name,
            id: data.id,
          };

          list.push(dataSend);
        }
      } else {
        return '';
      }

      if (file && file.length > 0 && (file.length + list?.length) <= props.numberFilesPermitted) {
        setFile([...file, ...list]);
        props.onChange([...file, ...list]);
      } else {
        setFile(list);
        props.onChange(list);
      }
      setIsShowFile(true);
    } catch (error) {
      console.log('error', error);

    } finally {
      setLoading(false);
    }
  };

  const handleRemove = (id: string) => {
    setLoading(true);
    const filterFile = file.filter((oneFile) => oneFile.id !== id);
    props.onChange(filterFile);
    setFile(filterFile);
    setLoading(false);
  };

  const handleSwitchShowFiles = () => setIsShowFile((oldShow) => !oldShow);

  const checkIsLimitFileAdded = useMemo(() => {
    return file?.length === props.numberFilesPermitted
  }, [file, props.numberFilesPermitted]);

  const renderTextLabel = useCallback(() => {
    if (props.helperText) {
      return (
        <FormHelperText
          error={props.error}
        >{props.helperText}
        </FormHelperText>
      )
    }
  }, [props]);


  return (
    <Box className={classes.container}>
      {loading ? <CircularProgress /> : (
        <>
          <Box className={classes.main}>
            <Box className={classes.containerInfoActions}>
              <Box className={classes.containerInfo}>
                <p
                  className={classes.label}>
                  {props.label.replace(/(<([^>]+)>)/gi, "")}
                </p>
                <span className={classes.legendText}>
                  Limite de arquivos: {props?.numberFilesPermitted}
                </span>
                <span className={classes.legendText}>
                  Arquivos adicionados: {file?.length || 0}
                </span>
              </Box>

              <Box className={classes.containerActions}>
                <button
                  type='button'
                  className={!file?.length ?
                    classes.boxButtonFileDisabled :
                    classes.buttonShow}
                  onClick={handleSwitchShowFiles}
                  disabled={!(file?.length || props?.value?.length)}
                >
                  {(isShowFile && file?.length > 0) ?
                    'Ocultar arquivos' :
                    'Visualizar arquivos'
                  }
                </button>
                <Box className={classes.containerButtonFile}>
                  <label
                    htmlFor={props.id}
                    className={checkIsLimitFileAdded ?
                      classes.boxButtonFileDisabled :
                      classes.boxButtonFile}
                  >
                    Escolher arquivos
                  </label>
                  <input
                    type='file'
                    id={props.id}
                    className={classes.input}
                    name={props.id}
                    onChange={handleChange}
                    disabled={checkIsLimitFileAdded ? true : props.disabled}
                    required={file?.length > 0 ? false : props.required}
                    multiple
                    title=""
                  />
                </Box>
              </Box>
            </Box>
            {isShowFile && (
              <Box
                className={classes.containerFileList}
              >
                {file && file.map((oneFile) => (
                  <div className={classes.document} key={oneFile.nome}>
                    <Link
                      className={classes.documentName}
                      href={oneFile?.url}
                      target='_blank'
                    >
                      <MdOutlineUploadFile className={classes.documentIcon} />
                      {`${oneFile?.nome}`}
                    </Link>
                    <button
                      type="button"
                      onClick={(): void => handleRemove(oneFile.id)}
                      className={classes.removeDoc}
                    >
                      <MdDelete className={classes.removeIcon} />
                    </button>
                  </div>
                ))}
              </Box>
            )}
          </Box>
          {renderTextLabel()}
        </>
      )}
    </Box>
  )
};